#banese{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title{
    width: 100%;
    max-width: 992px;
    letter-spacing: 0px;
    color: #02354D;
    text-align: left;
    font-size: 4.2rem;
    font-family: 'teko';
    position: relative;
    font-weight: normal;
    @media (max-width:550px){
      font-size: 2.125rem;
    }
  }
  p{
    text-align: left;
    letter-spacing: 0.09px;
    color: #FFFFFF;
    font-size: 1.125rem;
  }
  a{
    text-decoration: none;
  }

  .subtitle{
    font-size: 2.125rem;
    // margin-left: 61px;
    // width:50%;
    color: #FFFFFF;
    @media (max-width:550px){
      font-size: 1.55rem;
    }
  }

  .section-1{
    width: 70%;
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  .section-2{
    margin-top: 115px;
    width: 70%;
    display: flex;
    align-items: flex-start;
    position: relative;

    .title::before{
      content:"";
      position: absolute;
      height:124px;
      width:225px;
      background-color:#FF7778 ;
      opacity: 0.23;
      top: -3rem;
      left: -4rem;
    }
    .bolinhas2{
      position: absolute;
      right: -7.5rem;
      bottom: -16rem;
      z-index: -1;
      pointer-events: none;
      @media (max-width:800px){
        display: none;
       }
    }
  }
  .section-3{
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 95px;
    margin-bottom: 50px;
    @media (max-width:990px){
      flex-direction: column;
      .left,.right{
        width: 100% !important;
      }
      .right{
        text-align: center !important;
        align-items: center !important;
        .solicitar,.form{
          width: 100% !important;
          p{
            text-align: center !important;
            justify-content:center;
          }
        }
      }
    }
    @media (max-width:768px){
      .left{
        position: relative;
        margin-bottom: -3rem;
      }
    }
    @media (max-width:550px){
      .right .solicitar{
        p {
          flex-direction: column !important;
          align-items: center !important;
          a{
            font-size: 1rem !important;
            font-weight: bold;
          }
        }
      }
      .right .form p{
        font-size: 1rem !important;
      }
      .right ,.left{
        min-width: 320px !important;
      }
    }
    .left{
      min-width: 350px;
      width: 53%;
      background-color: #02354D;
      box-shadow: 13px 13px 15px #00000033;
      padding:92px 85px;
      position: relative;
      overflow: hidden;
      p{margin-top: 21px;}
      @media(max-width:1360px){
        padding: 92px 45px;
      }
      .details{
        position: absolute;
        pointer-events: none;
      }
      .blob3{
        left: -20rem;
        bottom: -15rem;
      }
      .blob4{
        right: -20rem;
        top: -20rem;
      }
      .bolinhas3{
        right: 6rem;
        bottom: -5.3rem;
      }
    }
    .right{
      min-width: 490px;
      width: 47%;
      display: flex;
      flex-direction: column;
      @media(max-width:1360px){
        .solicitar,.form{
          padding: 50px 45px !important;
        }
      }
      @media(max-width:768px){
        .solicitar{
          padding-top: 75px !important;
        }
      }
      .solicitar{
        background-color: #FF7778;
        padding: 48px 74px 50px 85px;
        p {
          display: flex;
          flex-direction: row;
          align-items: center;
          a{
            letter-spacing: 0.11px;
            color: #7A3B3C;
            font-size: 1.4rem;
            transition: filter 0.3s ease-in-out;
            &:hover{
              filter: brightness(1.2);
            }
          }
          .icon{
            color: #7A3B3C;
            min-width: 30px;
            min-height: 42px;
            margin-right: 10px;
          }
        }
      }
      .form{
        background-color: #3E92B7;
        padding: 63px 85px;
        strong{
          font-style: italic;
        }
        .button{
          background-color: #00C781;
          width: 100%;
          max-width: 185px;
          height: 46px;
          border: none;
          outline: none;
          transition: filter 0.5s ease-in-out;
          &:hover{
            filter: brightness(0.7);
          }
          a{
            color: #FFFFFF;
            font-family: 'teko';
            font-size: 1.55rem;
          }
        }
      }
    }
  }
}

@media (max-width:550px){
  #banese  .section-3{
    margin-top: 35px;
    width: 100%;
    .left{
      width: 90% !important;
    }
    .right .solicitar,.right .form{
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
  #banese .section-1{
    width: 90%;
  }
  #banese .section-2{
    width: 90%;
    margin-top: 76px;
    .title::before{
    width: 225px;
    height: 72px;
    left: 0rem;
    top: -2.4rem;
    }
  }
}