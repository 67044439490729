#input{
  width: 100%;
  .form-group {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .form-control {
    width: 100%;
    background-color: #FFFFFF;
    border: 2px solid #DADADA;
    height: 37px;
    padding-left: 12px;
    letter-spacing: 0.08px;
    color: #3E92B7;
    font-weight: bold;
    font-size: 1rem;
    &::-webkit-inner-spin-button{
      -webkit-appearance: none;
    }
    &:focus{
      outline: none;
    }
  }
  .label {
    letter-spacing: 0px;
    color: #999999;
    font-size: 1.55rem;
    font-family: 'teko';
    margin-bottom: 0;
    width: 100%;
    text-align: left;
  }


  //select
  .css-yk16xz-control {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 37px;
    padding-left: 5px;
    background-color: transparent;
    border: 2px solid #DADADA;
    position: relative;
  }
  .css-2b097c-container {
    width: 100%;
    height: 37px;
  }
  .css-1pahdxg-control{
    min-height: 37px !important;
    border-color: #DADADA;
    box-shadow: none;
  }
  .css-yk16xz-control{
    border-radius: 0;
    z-index: 1;
  }
  .css-1pahdxg-control{
    border-radius: 0;
    border: 2px solid #DADADA;
  }
  .css-b8ldur-Input {
    margin: 0 !important;
  }
  .css-g1d714-ValueContainer {
    margin-left: 5px;
    letter-spacing: 0px;
    color: #DADADA;
    opacity: 1;
    text-align: left;
    font-weight: 300;
    font-size: 0.9rem;
    font-family: 'Teko', sans-serif;
    font-style: normal;
    font-weight: 400;
  }
  .css-1wa3eu0-placeholder {
    text-align: left;
    width: 100%;
    letter-spacing: 0.08px;
    color: #DADADA;
    font-weight: bold;
    font-size: 1rem;
  }
  .css-1okebmr-indicatorSeparator{
    display: none;
  }

  .css-1gtu0rj-indicatorContainer{
    display: none;
  }
  .css-tlfecz-indicatorContainer svg {
    display: none;
  }
  .css-4ljt47-MenuList{
    background-color:  #EFEFEF;
    // top: -0.5rem;
    border-radius: 0;
    z-index: 2;
    div{
      letter-spacing: 0.08px;
      color: #999999;
      opacity: 0.55;
      font-weight: bold;
      font-size: 1rem;
    }
  }
  .css-26l3qy-menu{
    border-radius: 0;
    background-color:  #EFEFEF;
    margin: 0;
    z-index: 2;
  }
  .retangle{
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 15px solid #DADADA;
    position: absolute;
    right: 1rem;
    bottom: 0.7rem;
    z-index: 0;
    transition: all 0.5s ease-in-out;
  }
  //textarea
  .form-control-area {
    width: 100%;
    background-color: #FFFFFF;
    border: 2px solid #DADADA;
    height: 148px;
    padding-left: 12px;
    padding-top: 6px;
    letter-spacing: 0.08px;
    color: #3E92B7;
    font-weight: bold;
    font-size: 1rem;
    &::-webkit-inner-spin-button{
      -webkit-appearance: none;
    }
    &:focus{
      outline: none;
    }
  }
  ::placeholder {
    letter-spacing: 0.08px;
    color: #444444;
    opacity: 0.3;
    text-align: center;
    font-weight: 300;
    font-size: 1rem;
  }
  :-ms-input-placeholder {
    letter-spacing: 0.08px;
    color: #444444;
    opacity: 0.3;
    text-align: center;
    font-weight: 300;
  }
}