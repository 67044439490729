.text-banner-page{
    font-family: "Roboto";
    font-size: 40px;
}
.banner-page{
    margin-bottom: 30px;
    width: 100%;
    @media(max-width: 768px){
        width: 100%;
    }
}