#section-presidente {
  width: 100%;

  .section-1 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 2rem;
    @media (max-width: 550px) {
      margin-top: 5rem;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 60%;
      height: 660px;
      background-color: #2c627a;
      position: relative;
      margin: 0;
      padding: 0;
      padding-left: 12rem !important;
      @media (max-width: 768px) {
        max-width: 75%;
      }
      @media (max-width: 550px) {
        height: 750px;
        align-items: flex-end;
        padding-bottom: 2rem !important;
        padding-left: 1rem !important;
        max-width: 100%;
      }
    }
    .container-card {
      position: absolute;
      left: -12rem;
      height: 550px;
      width: 360px;
      bottom: 0;
      @media (max-width: 768px) {
        height: 450px;
        width: 260px;
        left: -8rem;
      }
      @media (max-width: 550px) {
        top: -2rem;
        right: 0;
        left: 0;
        width: 100%;
        height: 300px;
        margin: 0 auto;
        justify-content: center;
        display: flex;
      }
      img {
        width: 100%;
        height: 100%;
        @media (max-width: 550px) {
          width: 200px;
        }
      }

      div {
        position: absolute;
        height: 99px;
        width: 450px;
        background-color: #02354d;
        display: flex;
        justify-content: center;
        align-items: center;
        left: -3rem;
        bottom: 0;
        @media (max-width: 550px) {
          width: 100vw;
          left: 0;
          text-align: center;
          padding: 10px 20px;
        }
    }
      h3 {
        font-family: 'Teko';
        font-weight: 400;
        letter-spacing: 0px;
        color: #ffffff;
        font-size: 2.1rem;
        margin: 0;
      }
    }
    ul,
    li {
      list-style: none;
    }
    ul {
      margin: 0;
    }
    li {
      letter-spacing: 0.08px;
      color: #ffffff;
      font-weight: 300;
      font-size: 1rem;
      max-width: 490px;
      text-align: left;
      margin-bottom: 2rem;
      @media (max-width: 550px) {
        max-width: 355px;
      }
    }
    li:last-child {
      margin: 0;
    }
  }
  .section-2 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 2rem;

    @media (max-width: 768px) {
      text-align: center;
    }

    .title {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
      @media (max-width: 768px) {
        width: 100%;
       }

      h1 {
        width:100%;
        font-family: 'Teko';
        font-weight: 400;
        letter-spacing: 0px;
        color: #444444;
        font-size: 2.4rem;
        margin-bottom: 0;
      }
      h3 {
        font-family: 'Teko';
        font-weight: 400;
        letter-spacing: 0px;
        color: #999999;
        font-size: 1.6rem;
        margin: 0;
      }
    }
  }
}
