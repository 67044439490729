#sociedade {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .obs{
        line-height: 2.2rem;
    }
    .section-1 {
        width: 70%;
        display: flex;
        align-items: flex-start;
    }
    .section-2 {
        max-width: 100%;

        margin-bottom: 3rem;
        z-index: 2;
        @media (min-width: 900px) {
            margin-bottom: 18rem;
        }
        .dropdownSociedade {
            position: relative;

            .dropdownSociedadeButton {
                width: 100%;
                height: 3rem;
                box-shadow: 0px 2px 12px 1px rgb(193, 192, 192),
                    inset 0px -1px 1px 1px rgb(221, 221, 221);
                color: black;
                opacity: 0.3;
                background-color: #fefefe;
                border: none;
                border-radius: 0.1rem;
                font-size: 0.8rem;
                z-index: 10;
                filter: blur(0);
                -webkit-filter: blur(0);
            }

            svg {
                font-size: 2rem;
                position: absolute;
                right: 2%;
                top: 10%;
            }

            .dropdownSociedadeList {
                display: block;
                position: absolute;
                top: 98%;
                width: 100%;
                background-color: #fefefe;
                min-width: 160px;
                z-index: 101;
                font-family: 'Roboto-light';
                text-align: center;
                transform: translateY(0) scaleY(0);
                transform-origin: top;
                opacity: 0;
                transition: 0.2s ease-in-out;
                filter: blur(0);
                -webkit-filter: blur(0);
                li {
                    color: #777777;
                    text-decoration: none;
                    display: block;
                    font-family: 'Roboto';
                    padding: 0.7rem 0.8rem;
                    transition: 0.1s ease-in-out;
                    a {
                        text-decoration: none;
                        color: #777777;
                    }
                }
                @media (min-width: 500px) {
                    padding: 0.3rem 0;
                }
                li:first-child {
                    margin-top: 2rem;
                }
                li:last-child {
                    margin-bottom: 2rem;
                }

                li:hover {
                    cursor: pointer;
                    box-shadow: 0px 2px 2px 2px rgb(232, 232, 232);
                }
            }

            .dropdownSociedadeList.showList {
                display: block;
                transform: scaleY(1);
                opacity: 1;
                border-bottom: 1px solid rgb(208, 208, 208);
                border-left: 1px solid rgb(208, 208, 208);
                border-right: 1px solid rgb(208, 208, 208);
            }
        }

        #sociedadeDownloadButton {
            height: 3.5rem;
            width: 11rem;
            font-family: 'Teko';
            font-size: 1.5rem;
            background-color: #00c781;
            color: #f8f8f8;
            border: none;
        }

        div.h2Container {
            @media(max-width:480px){
                h2#subtituloSociedade::after,h2#subtituloSociedade::before {
                    display: none;
                }
            }
            h2#subtituloSociedade::after {
                content: '';
                width: 329px;
                height: 294px;
                background-color: #ff7778;
                border: 1px solid #707070;
                opacity: 0.23;
                position: absolute;
                left: 9rem;
                top: -2.5rem;
                z-index: -1;
            }
            h2#subtituloSociedade::before {
                content: '';
                width: 229px;
                height: 59px;
                background-color: #ff7778;
                border: 1px solid #707070;
                opacity: 0.23;
                position: absolute;
                right: 0;
                bottom: -3rem;
                z-index: -1;
            }
            h2#subtituloSociedade {
                position: relative;
                width: 100%;
                height: 6rem;
                //background-color: rgb(28, 139, 194);

                background-image: url('../../../assets/images/Secretaria/Sociedade/Grupo430.jpg');
                background-size: cover;

                color: #f8f8f8;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Teko';
                font-weight: 400;
                font-size: 2rem;
                text-align: center;

                line-height: 2.5rem;

                @media (min-width: 500px) {
                    font-size: 3rem;
                }
            }
        }

        div.imageAndCards {
            gap: 3rem;
            margin: 0;
            padding: 0;
            @media (min-width: 990px) {
                margin-left: 12%;
            }
            @media (min-width: 1200px) {
                margin-left: 24%;
            }

            div#infoCardBig {
                transform: scaleY(0);
                opacity: 0;
                position: absolute;
                top: -15%;
                left: -250px;
                width: 550px;
                height: auto;
                background: #2c627a;
                color: white;
                z-index: 10;
                transition: all 0.2s ease-in-out;
                transform-origin: top;
                max-height: 60vh;
                overflow-y: scroll;

                &::-webkit-scrollbar{
                    width: 10px;
                }
                &::-webkit-scrollbar-track {
                  background-color: #eee;
                }
                   
                  /* Handle */
                &::-webkit-scrollbar-thumb {
                  background: #ccc; 
                }
                  
                  /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                  background: #aaa; 
                }
                .dropdown-item{
                    font-size: 1rem !important;
                    a{
                        font-size: 1rem !important;

                    }
                }
                ul {
                    padding-left: 2rem;
                    padding-top: 1.5rem;
                    list-style-type: square;
                    z-index: 10;

                    li {
                        font-size: 1rem !important;
                        font-family: 'Roboto';
                        font-weight: 100;
                        margin: 1rem 0 1rem 1rem;
                        z-index: 10;
                        padding-right: 1rem;
                    }
                }
                @media (max-width: 300px) {
                    display: none;
                }
                @media (max-width: 990px) {
                    display: none;
                }
                @media (min-width: 1200px) {
                    display: block;
                    transform: scaleY(0);
                    opacity: 0;
                    left: -200px;
                    transition: all 0.2s ease-in-out;
                    transform-origin: top;
                    width: 450px;
                }
            }

            div#infoCardBig.showInfoCardBig {
                transform: scaleY(1);
                opacity: 1;

                @media (min-width: 300px) {
                    display: none;
                }
                @media (min-width: 990px) {
                    display: none;
                }
                @media (min-width: 1200px) {
                    display: block;
                    transform: scaleY(1);
                    opacity: 1;
                    left: -250px;
                    width: 550px;
                }
            }

            div#infoCard {
                display: block;
                transform: scaleY(0);
                opacity: 0;
                position: absolute;
                top: -15%;
                left: -200px;
                width: 550px;
                height: auto;
                background: #2c627a;
                color: white;
                z-index: 10;
                transition: all 0.2s ease-in-out;
                transform-origin: top;

                ul {
                    padding-left: 2rem;
                    padding-top: 1.5rem;
                    list-style-type: square;
                    z-index: 10;

                    li {
                        font-size: 0.8rem;
                        font-family: 'Roboto';
                        font-weight: 100;
                        margin: 1rem 0 1rem 1rem;
                        z-index: 10;
                        padding-right: 1rem;
                    }
                }
                @media (min-width: 300px) {
                    position: relative;
                    display: none;
                    top: 0;
                    left: 0;
                    height: auto;
                }
                @media (min-width: 990px) {
                    display: none;
                }
                @media (min-width: 1200px) {
                    display: none;
                    left: -200px;
                    width: 450px;
                }
            }
            div#infoCard.showInfoCard {
                display: none;
                transform: scaleY(1);
                opacity: 1;
                @media (min-width: 770px) {
                    display: block;
                }
                @media (min-width: 990px) {
                    display: block;
                }
                @media (min-width: 1200px) {
                    display: none;
                }
            }

            div#smallInfo0,
            div#smallInfo1,
            div#smallInfo2,
            div#smallInfo3,
            div#smallInfo4,
            div#smallInfo5 {
                display: none;
                background: #2c627a;
                color: white;
                ul {
                    padding-left: 2rem;
                    padding-top: 1.5rem;
                    list-style-type: square;
                    z-index: 10;

                    li {
                        font-size: 0.8rem;
                        font-family: 'Roboto';
                        font-weight: 100;
                        margin: 1rem 0 1rem 1rem;
                        z-index: 10;
                        padding-right: 1rem;
                    }
                }
            }
            div#smallInfo0.showSmall,
            div#smallInfo1.showSmall,
            div#smallInfo2.showSmall,
            div#smallInfo3.showSmall,
            div#smallInfo4.showSmall,
            div#smallInfo5.showSmall {
                width: 100%;
                display: block;
                padding: 20px 0;
                @media (min-width: 770px) {
                    display: none;
                }
                .sociedade-info{
                    flex-direction: column;
                    background-color: #2c627a;
                    color: white;
                    padding: 0;
                    text-align: start;

                    .obs{
                        padding: 0 0 20px 0 !important;
                        display: flex;
                        flex-direction: column;
                        font-size: 1rem;
                        span{
                            padding-top: 10px;
                        }
                    }
                }
            }

            div.imagem {
                position: relative;
                max-width: none;
                background-color: #37718a;
                padding: 1rem 0;

                #arrowToCards {
                    position: absolute;
                    top: 32%;
                    left: 82%;
                    width: 27%;
                    z-index: 2;
                    font-size: 1rem;
                }

                span#selecioneUmaOpcao {
                    font-family: 'Teko';
                    font-weight: 300;
                    font-size: 3rem;
                    color: #fefefe;
                    text-align: center;
                }

                @media (min-width: 650px) {
                    max-width: 28%;
                }
            }

            div.cards {
                background-color: white;
                padding: 0;
                width: 100vw;
                div {
                    background-color: white;
                    div {
                        background-color: white;
                        div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 2rem 1.7rem;
                            background-color: white;
                            color: #2c627a;
                            font-size: 1rem;
                            font-family: 'Teko';
                            text-align: center;
                        }

                        div:hover {
                            cursor: pointer;
                            background-color: #3e91b6;
                            color: #fefefe;
                            transform: scale(1.2);
                            z-index: 10;
                        }
                    }
                }
            }
        }
    }
    .section-2.moreMarginBotttom {
        margin-bottom: 20rem;
    }
}
