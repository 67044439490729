#Comissao{
    .css-yk16xz-control {
        display: flex;
        justify-content: center;
        height: 37px;
        padding-left: 5px;
        box-shadow: 5px 5px 20px #ddd;
        width: 79%;
        margin: 0 auto;
        background-color: white;
        border: 0;
    ;
        position: relative;
      }
      .css-2b097c-container {
        width: 100%;
        height: 37px;
      }
      .css-1pahdxg-control{
        min-height: 37px !important;
        display: flex;
        justify-content: center;
        height: 37px;
        padding-left: 5px;
        box-shadow: 5px 5px 20px #ddd;
        width: 79%;
        margin: 0 auto;
        background-color: white;
        border: 0;
        :hover{
          :active{
            display: flex;
            justify-content: center;
            height: 37px;
            padding-left: 5px;
            box-shadow: 5px 5px 20px #ddd;
            width: 79%;
            margin: 0 auto;
            background-color: white;
            border: 0;
          }
        }
      }
      .css-yk16xz-control{
        border-radius: 0;
        z-index: 1;
      }
      .css-1pahdxg-control{
        border-radius: 0;
        border: 2px solid #DADADA
    ;
      }
      .css-b8ldur-Input {
        margin: 0 !important;
      }
      .css-g1d714-ValueContainer {
        margin-left: 5px;
        letter-spacing: 0px;
        color: #DADADA
    ;
        opacity: 1;
        text-align: center;
        font-weight: 300;
        font-size: 0.9rem;
        font-family: 'Teko', sans-serif;
        font-style: normal;
        font-weight: 400;
      }
      .css-1wa3eu0-placeholder {
        text-align: center;
        width: 100%;
        letter-spacing: 0.08px;
        color: #DADADA;
        font-weight: bold;
        font-size: 1rem;
      }
      .css-1okebmr-indicatorSeparator{
        display: none;
      }  .css-1gtu0rj-indicatorContainer{
        display: none;
      }
      .css-tlfecz-indicatorContainer svg {
        display: none;
      }
      .css-4ljt47-MenuList{
        background-color:  #fff;
        width: 100%;
        margin: 0 auto;
        // top: -0.5rem;
        border-radius: 0;
        z-index: 2;
        div{
          letter-spacing: 0.08px;
          color: #333;
          opacity: 0.55;
          font-weight: bold;
          font-size: 1rem;
        }
      }
      .css-26l3qy-menu{
        border-radius: 0;
        background-color:  #fff;
        margin: 0 auto;
        z-index: 2;
        width: 79%;
        transform: translate(13%);
      }
      .retangle{
        width: 0; 
        height: 0; 
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 15px solid #DADADA
    ;
        position: absolute;
        right: 1rem;
        bottom: 0.7rem;
        z-index: 0;
        transition: all 0.5s ease-in-out;
      }
}