#carousel-home {
    .carousel label {
        margin: 0;
        padding: 0;
    }      

    .carousel {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        position: relative;
        margin-bottom: 2rem;
        @media(max-width: 768px){
            margin-bottom: -11rem;
        }

    }
    .carousel-stage {
        overflow: hidden;
        font-size: 0;
        white-space: nowrap;
        width: 100%;
        height: 32.5vw;
        //min-height: 700px;
        position: relative;
        scroll-snap-type: x mandatory;
        overflow-x: hidden;
        scroll-behavior: smooth;
        @media(max-width: 768px){
            width: 100%;
            height: 453px;
        }

        .carousel-item {
            display: inline-block;
            text-indent: 0;
            width: 100%;
            height: 100%;
            //min-height: 700px;
            scroll-snap-align: center;
            @media(max-width: 768px){
                width: 100%;
                height: 453px;
            }
        }
    }
    .images{
        @media(max-width: 768px){
            width: 100%;
            height: 290px;
        }
    }
}

.MuiSvgIcon-root{
    @media(max-width:768px){
        margin-top: -150px;
    }
}