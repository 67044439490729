#loctel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .section-1 {
        width: 70%;
        display: flex;
        align-items: flex-start;
    }

    .section-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        section.contatos {
            width: 80%;
            box-shadow: 16px 16px 16px #00000029;
            margin-bottom: 2rem;
            section.presentationContatos {
                width: 172px;
                height: 143px;
                text-align: center;
                margin: 0 auto;

                p.title {
                    font-family: 'Teko';
                    font-size: 38px;
                    margin: 0;
                    height: 48px;
                    color: #02283a;
                }

                .blueRecContatos {
                    width: 56px;
                    height: 6px;
                    background-color: #3e92b7;
                    margin: 0 auto;
                }

                .hand {
                    margin: 19px auto 0 auto;

                    svg {
                        font-size: 2rem;
                        color: #dadada;
                    }
                }

                .descriptionPresentationContatos {
                    width: 156px;
                    height: 37px;
                    font-size: 14px;
                    margin: 8.53px auto 0 auto;
                    color: #999999;
                }

                @media (min-width: 1000px) {
                    height: 97px;
                    margin-left: 75px;
                    width: 75%;

                    p.title {
                        font-size: 68px;
                        height: 78px;
                    }
                    .hand,
                    .descriptionPresentationContatos {
                        display: none;
                    }
                }
                @media (min-width: 1322px) {
                    text-align: left;
                    .blueRecContatos {
                        width: 104px;
                        height: 14px;
                        margin: 0;
                    }
                }
            }
            section.cardsContatos {
                margin: 19px auto 0 auto;
                display: flex;
                margin-left: -30px;
                flex-wrap: wrap;
                flex-direction: row;
                gap: 15px;
                width: 95%;
                justify-content: center;

                .tileSetor {
                    position: relative;
                    width: 320px;
                    padding: 36px 112px 36.33px 36px;
                    background-color: #f2f2f2;
                    cursor: pointer;
                    .smallBlueRecContato {
                        width: 44px;
                        height: 7px;
                        margin-left: -30px;
                        background-color: #3e92b7;
                    }
                    p {
                        margin-top: 9.67px;
                        font-family: 'Teko';
                        font-size: 28px;
                        font-weight: 400;
                        margin-left: -30px;
                        color: #02354d;
                    }
                    .dropDownInfo {
                        position: absolute;
                        left: 0px;
                        transform: scaleY(0);
                        transform-origin: top;
                        opacity: 0;
                        transition: all 0.2s ease-in-out;
                        width: 100%;
                        background-color: #f2f2f2;
                        padding-left: 70px;
                        z-index: 10;
                        p{
                            color: white;
                            font-size: 18px;
                            font-family: 'Roboto';
                            font-weight: 500;
                        }
                        .dropDownText {
                            background-color: #f2f2f2;
                            background-color: #2C627A;
                            width: 80%;
                        }
                        &.showDropText {
                            transform: scaleY(1);
                            opacity: 1;
                            background-color: #2C627A;
                            transition: all 0.3s ease-in-out;
                        }
                    }
                }
            }
            section.infoLoc {
                margin: 10px auto 66px -30px;
                padding-top: 28px;
                padding-bottom: 44px;
                width: 100%;
                background-color: #3e92b7;

                div.regionais,
                div.salaAdvogados {
                    p {
                        font-family: 'Teko';
                        font-size: 38px;
                        color: #02283a;
                        text-align: left;
                        margin-bottom: 10px;
                    }

                    div {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        align-items: left;
                        justify-content: left;
                        margin-top: 24px;
                    }

                    div span {
                        font-family: 'Roboto';
                        font-size: 20px;
                        font-weight: medium;
                        color: #fff;
                        letter-spacing: 3px;
                    }
                    div span:first-child {
                        font-weight: 400;
                        letter-spacing: 3px;
                        font-weight: bold;
                    }
                    div.regionaisFlex,
                    div.salaAdvogadosFlex {
                        margin: 0 auto;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        justify-content: left;
                        align-items: left;
                        width: 100%;
                    }
                    div.salaAdvogadosFlex {
                        display: flex;
                        flex-direction: column;
                    }
                }

                div.stroke {
                    width: 80%;
                    height: 2px;
                    background-color: #02283a;
                    margin: 24px auto;
                    opacity: 0.15;
                }
                @media (min-width: 1280px) {
                    width: 100%;
                    div.regionaisFlex,
                    div.salaAdvogadosFlex {
                        gap: 1rem;
                    }
                }
                @media (min-width: 1322px) {
                    width: 100%;
                    div.regionais,
                    div.salaAdvogados {
                        width: 80%;
                        text-align: left;
                        margin: 0 auto 0;
                        p {
                            text-align: left;
                            font-size: 40px;
                            font-weight: 500;
                            letter-spacing: 3px;
                        }
                    }
                        div.regionaisFlex,
                        div.salaAdvogadosFlex {
                            justify-content: start;
                            margin: 0;
                            div span:nth-child(2) {
                                width: 100%;

                            }
                        }
                        div.salaAdvogadosFlex {
                            width: 90%;
                            div:nth-child(6) span:nth-child(2) {
                                width: 50%;
                            }
                        }
                    
                }
            }
        }

        section.mapa {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 66px;
            p.mapaTitle {
                width: 200px;
                font-family: 'Teko';
                font-size: 40px;
                color: #013e5a;
                text-align: center;
                line-height: 42px;
                margin-bottom: 0;
            }
            p.mapaText {
                width: 290px;
                font-size: 15px;
                color: #7c7c7c;
                text-align: center;
                margin-top: 10px;
                margin-bottom: 32px;
            }

            div.mapaMap {
                width: 314px;
                height: 350px;

                @media (min-width: 500px) {
                    width: 100%;
                }
            }

            @media (min-width: 500px) {
                width: 80%;
            }
        }
    }
}
