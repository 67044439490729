.memorial{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  #section-1 {
    width: 70%;
    display: flex;
    align-items: flex-start;
    @media (max-width: 550px) {
      width: 100%;
      padding-left: 20px;
    }
  }
  #section-2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (max-width:790px) {
      flex-direction: column-reverse;
    }
    #estatua{
      background: url("../../assets/images/Memorial/Desktop/contato.png");
      img{
        max-width: 71vw;
        height:58vh;
        @media (max-width:1390px) {
          height: 70vh;
        }
        @media (max-width:790px) {
          background: url("../../assets/images/Memorial/Mobile/mask-picture.png");
          max-width: 100%;
          height: 30vh;
        }
      }
    }
    #blueBg{
      background: url("../../assets/images/Memorial/Desktop/div-pattern.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 50vw;
      height: 85vh;
      @media (max-width:1390px) {
        height: 95vh;
      }
      @media (max-width:790px) {
        background-repeat: no-repeat;
        width: 100vw;
        height: 100vh;
        position: relative;
        top:-70px;
        left:-10px;
      }
    }
    #content{
      position: relative;
      top:15vh;
      left:5vw;
      @media (max-width:1390px) {
        left:12vw;
      }
      @media (max-width:1200px) {
        left:8vw;
      }
      @media (max-width:790px) {
        left:0;
      }
      h2{
        font: normal normal normal 3.8em/71px Teko;
        color: #FFFFFF;
        @media (max-width:1390px) {
          font: normal normal normal 3.2em/61px Teko;
        }
        @media (max-width:1200px) {
          font: normal normal normal 2.8em/60px Teko;
        }
        @media (max-width:790px) {
          font: normal normal normal 2.5em/55px Teko;
          text-align: center;
          margin: 0 5vw;
        }
      }
      p{
        color:#FFF;
        font-size: 1.2em;
        
        @media (max-width:790px) {
          font: normal normal normal 1.4em/40px Teko;
          text-align: center;
          margin: 0 10vw;
        }
      }
    }
  }
  #section-3{
    background: url("../../assets/images/Memorial/Desktop/div-bg-pattern-2.png");
    display: flex;
    padding-left: 10vw;
    padding-top: 20vh;
    padding-bottom: 20vh;
    justify-content: space-around;
    @media (max-width:790px) {
      background: url("../../assets/images/Memorial/Mobile/div-bg-pattern-2@2x.png");
      background-repeat: no-repeat;
      background-size: 100%;
      flex-direction: column;
      padding-top: 18vh;
      padding-left: 12vw;
      margin-top: -150px;
    }
    #content{
      width: 20%;
      @media (max-width:790px) {          
       width: 70%;
      }
      h3{
        font: normal normal normal 38px/40px Teko;
        color: #444444;
      }
    }
    #text-1{
      background: url("../../assets/images/Memorial/Desktop/div-text-1.png");
      max-width: 250px;
      max-height: 400px;
      padding: 20vh 1vw 0;
      @media (max-width:790px) {          
       max-width: 280px;
       margin-bottom: 4vh;
      }
    }
    #text-2{
      background: url("../../assets/images/Memorial/Desktop/div-text-2.png");
      max-width: 250px;
      max-height: 400px;
      padding: 20vh 1vw 0;
      @media (max-width:790px) {          
        max-width: 280px;
        margin-bottom: 4vh;
      }
    }
    #text-3{
      background: url("../../assets/images/Memorial/Desktop/div-text-3.png");
      max-width: 250px;
      max-height: 400px;
      padding: 20vh 1vw 0;
      @media (max-width:790px) {          
        max-width: 280px;
      }
    }
  }
  #section-4{
    h3{
      font: normal normal normal 2.5em/40px Teko;
        color: #444444;
    }
    #content{
      display: flex;
      padding-left: 13vw;
      padding-top: 10vh;
      padding-bottom: 10vh;
      @media (max-width:790px) {
        flex-direction: column;
      }
    }
    #content-1{
      width: 30%;
      @media (max-width:790px) {
        width: 80%;
      }
    }
    #content-2{
      margin-left: 5vw;
      width: 30%;
      @media (max-width:790px) {
        width: 80%;
        margin-left: 0;
      }
    }    
    #galery{
      margin-bottom: 10vh;
      margin-left: 10vw;
      margin-right: 10vw;
      img{
        height: 420px;
        width: 95%;
      }
      .rec.rec-arrow {
        display: none;
      }
      #buttonPages{
        margin: 3vh 1vw;
        background-color: #ff7778;
        padding: 5px 10px;
        border: none;
        &:hover {
          background-color: #02283a;
          color: white;
        }
      }
      #buttonPages-active{
        margin: 3vh 1vw;
        background-color: #02283a;
        color: white;
        padding: 5px 10px;
        border: none;
      }
    
    }
  }
  #section-5{
    background-image: url("../../assets/images/Memorial/Desktop/contato.png");
    background-repeat: no-repeat;
    color:#FFF;
    padding: 10vh 20vw;
    margin: 0 5vw 15vh 5vw;
    @media (max-width:790px) {
      background-image: url("../../assets/images/Memorial/Mobile/contato.png");
      padding: 15vh 10vw;
      margin: 0 4vw 8vh 4vw;
      background-size: cover;
    }
    h2{
      font: normal normal normal 4em/71px Teko;
      text-align: center;    
      @media (max-width:790px) {
        font: normal normal normal 2.5em/58px Teko;
      }  
    }
    p{
      text-align: center;
      margin-top: 5vh;
      @media (max-width:790px) {
        font: normal normal normal 18px/40px Roboto;
      }  
    }
    strong{
      @media (max-width:790px) {
        font: normal normal bolder 24px/40px Roboto;
      } 
    }
  }
}