.container-select{
    display: flex;
	flex-direction: column;
	text-align: left;
	font: normal normal normal 21px/41px Futura Bk BT;
	letter-spacing: 0px;
	color: #fff;
	opacity: 1;
    margin: 0 0.5rem;
	font-family: "Roboto", "Courier New", Courier, monospace;
}

select{
	border: 1px solid #999;
	border-radius: 5px;
	opacity: 1 !important;
	width: 10rem;
	height: 40px;
	padding: 0 0.5rem;
	outline: #fff;
	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
	text-align: center;
	font: normal normal medium 16px/41px Roboto;
	letter-spacing: 0px;
	font-size: 16px;
	font-family: "Roboto", "Courier New", Courier, monospace;
	color: #999;
	opacity: 0.5;
	background: #fff url('../../../../assets//images/Select/container.png') 95.5% 50% no-repeat;
}

option{
	text-align: center;
	font: normal normal medium 16px/41px "Roboto";
	letter-spacing: 0px;
	color: #444;
	opacity: 1;
	font-family: "Roboto", "Courier New", Courier, monospace;
}

@media(max-width: 1380px){
	select{
		text-align: left;
	}
}

@media (max-width: 600px) {
    .container-select{
        text-align: center;
    }
}