#ementarioted {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .section-1 {
        width: 70%;
        display: flex;
        align-items: flex-start;
    }
    .section-2 {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        section.presentation {
            width: 314px;
            height: 292px;
            //border: 2px solid deeppink;
            display: grid;
            grid-template-areas:
                'a'
                'b'
                'c';
            justify-content: center;
            background-color: #fff;
            box-shadow: 1rem 1rem 1rem #00000029;
            .blueRec {
                grid-area: a;
                width: 128px;
                height: 8px;
                margin: 36px auto 0 auto;
                background-color: #3e92b7;
                //border: 1px dashed darksalmon;
            }
            .smallDescription {
                grid-area: b;
                width: 213px;
                height: 43px;
                margin: 19px auto 0 auto;
                font-family: 'Roboto';
                font-weight: 300;
                font-size: 16px;
                color: #999999;
                //border: 1px dashed darksalmon;
            }
            .dateDescription {
                grid-area: c;
                width: 241px;
                height: 142px;
                margin-top: 8px;
                margin-bottom: 36px;
                padding-top: 8px;
                font-family: 'Teko';
                font-size: 38px;
                color: #02354d;
                letter-spacing: 0;
                line-height: 1.15;
                //border: 1px dashed darksalmon;
                span {
                    font-family: 'Teko';
                    color: #ff7778;
                }
            }
        }
        section.cards {
            width: 85%;
            margin-top: 26px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 4px;
            padding: 0 2rem;
            article.tileCard {
                width: 260px;
                height: 237px;
                background-color: #f2f2f2;
                margin: 0 0 0 0;
                padding-left: 55px;
                padding-top: 63px;
                transition: all ease-in-out 0.1s;
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 75px;
                }
                div.smallBlueRec {
                    width: 44px;
                    height: 7px;
                    background-color: #3e92b7;
                }
                p {
                    width: 150px;
                    height: 60px;
                    margin-top: 14.67px;
                    font-family: 'Teko';
                    font-size: 21px;
                    color: #2c627a;
                    text-align: left;
                }
            }
            article.tileCard:hover {
                background-color: #3e92b7;
                transform: translateY(-10px);
                cursor: pointer;
                div.smallBlueRec {
                    background-color: #fff;
                    cursor: pointer;
                }

                p {
                    color: #fff;
                    cursor: pointer;
                }
            }
            article.tileCard.active {
                background-color: #02354d;
                transform: translateY(-10px);
                div.smallBlueRec {
                    background-color: #fff;
                }

                p {
                    color: #fff;
                }
            }
        }
        section.infoTile {
            width: 90%;
            margin-bottom: 75px;
            height: 0;
            transform: scaleY(0);
            transform-origin: top;
            opacity: 0;
            transition: all ease-in-out 0.1s;
            p {
                width: 100%;
                text-align: left;
                font-family: 'Teko';
                font-size: 38px;
            }
            pre {
                white-space: pre-line;
                word-break: keep-all;
                text-align: left;
                font-family: 'Roboto';
                font-weight: 300;
                font-size: 16px;
            }
        }
        section.infoTile.showInfoTile {
            transform: scaleY(1);
            opacity: 1;
            height: auto;
        }
        @media (min-width: 1000px) {
            width: 100%;
            section.presentation {
                grid-template-areas:
                    'a b'
                    'a c';
                justify-content: start;
                width: 75%;
                height: auto;
                padding-top: 50px;
                padding-bottom: 50px;
                .blueRec {
                    width: 14px;
                    height: 104px;
                    margin: 0 21px 0 80px;
                }
                p.smallDescription {
                    width: 475px;
                    height: 22px;
                    margin: auto 0;
                    padding: 0;
                    text-align: left;
                }
                p.dateDescription {
                    width: auto;
                    height: auto;
                    //height: 97px;
                    font-size: 50px;
                    margin: auto 0;
                    padding: 0;
                    text-align: left;
                }
            }
        }
    }
}
