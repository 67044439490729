#defesa{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .section-1{
    width: 70%;
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  .section-2{
    width: 70%;
    margin-top: 30px;
    margin-bottom: 118px;
    .container-form::before{
      content: "";
      width: 250px;
      height: 250px;
      position: absolute;
      right: -7rem;
      top: 35%;
      background-color: #FF7778 ;
      opacity: 0.23;
      z-index: -1;
    }
    .container-form{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      box-shadow: 16px 16px 16px #00000029;
      background-color: #FFFFFF;

      .form{
        width: 55%;
        margin: 85px 95px;
      }
      .container-perga::after{
        content: "";
        width: 70px;
        height: 70px;
        position: absolute;
        left: -2rem;
        top: 10%;
        background-color: #FF7778 ;
        opacity: 0.23;
      }
      .container-perga{
        position: relative;
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #02354D;
        padding: 0 95px;
        img{
          width: 100%;
          min-width: 220px;
          max-width: 300px;
        }
        p{
          letter-spacing: 0.08px;
          color: #FFFFFF;
          font-size: 1rem;
          font-weight: 300;
          margin-top: 41px;
          text-align: center;
        }
      }
    }

    .button{
      margin-top: 16px;
      background-color: #00C781;
      width: 245px;
      min-width: 245px;
      height: 80px;
      border: 0;
      transition: filter 0.3s ease-in-out;
      color: #FFFFFF;
      font-size: 2.2rem;
      font-family: 'teko';
      &:hover{
        filter: brightness(0.8);
      }
    }
  }
}

@media (max-width:1000px){
  #defesa{
    .section-2{
      width: 90%;
      .container-form::before{
        display: none;
      }
    }
    .section-2 .container-form .container-perga{
      padding: 40px;
    }
  }
}
@media (max-width:768px){
  #defesa{
    .section-1{
      width: 90%;
    }
    .section-2{
      .container-form{
        flex-direction: column-reverse;
        .container-perga{
          width: 100%;
        }
        .form{
          width: 90%;
          margin: 19px 33px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}