.modalCookies{
  background-color: #2C627A;
  height: auto;
  width: 90vw;
  position: fixed;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  margin-bottom: 2vh;
  margin-left: 5vw;
  border-radius: 10px;

  .leftMobile, .rightMobile, .balls {
    display: none;
  }
  @media(min-width:900px) and (max-width:1050px){    
    background-color: #2C627A;
    height: auto;
    width:85vw;
    margin-left:8vw;
    .left, .right{
      display: none;
    }
    .leftMobile, .rightMobile, .balls {
      display: initial;
      position: relative;
    }
    .balls{
      height: 16vh;
      right: 0;
      position: absolute;
      bottom: 0;
    }
    .leftMobile{
      left: 0;
    }
    .rightMobile{
      right:0;
      top:-0.5vh;
    }
  }
  @media(min-width:700px) and (max-width:900px){    
    background-color: #2C627A;
    height: auto;
    width:85vw;
    margin-left:8vw;
    .left, .right{
      display: none;
    }
    .leftMobile, .rightMobile, .balls {
      display: initial;
      position: relative;
    }
    .balls{
      top: -12vh;
      width: 16vw;
      height: 16vh;
      left: 60vw;
      position: absolute
    }
    .leftMobile{
      left: 0;
    }
    .rightMobile{
      left:65vw;
      top:-0.5vh;
    }
  }
  @media(min-height:1250px) and (max-height:1400px){  
    height: auto;
    
  }  
  @media (min-width:500px) and (max-width:600px) {
    background-color: #2C627A;
    height: auto;
    width:80vw;
    margin:0 10vw;
    .left, .right{
      display: none;
    }
    .leftMobile, .rightMobile, .balls {
      display: initial;
      position: relative;
    }
    .balls{
      top: -12vh;
      width: 16vw;
      height: 16vh;
      left: 60vw;
      position: absolute
    }
    .leftMobile{
      left: 0;
    }
    .rightMobile{
      right: 0;
      top:-0.5vh;
    }
  }
  
  @media (max-width:500px) {
    background-color: #2C627A;
    height: auto;
    width:80vw;
    margin-left:10vw;
    .left, .right{
      display: none;
    }
    .leftMobile, .rightMobile, .balls {
      display: initial;
      position: relative;
      z-index: -1;
    }
    .balls{
      top: -8vh;
      width: 20vw;
      height: 30vh;
      left: 55vw;
      position: absolute
    }
    .leftMobile{
      left: 0;
    }
    .rightMobile{
      right: 0;
      top:-7vh;
    }
  }
  @media (max-width:340px) {
    background-color: #2C627A;
    height: auto;
    width:80vw;
    margin-left:10vw;
    .left, .right{
      display: none;
    }
    .leftMobile, .rightMobile, .balls {
      display: initial;
      position: relative;
    }
    .balls{
      top: -12vh;
      width: 20vw;
      height: 18vh;
      left: 55vw;
      position: absolute
    }
    .leftMobile{
      left: 0;
    }
    .rightMobile{
      right: 0;
      top:-0.5vh;
    }
  }

  @media (min-width:530px) and (max-height:730px) { 
    background-color: #2C627A;
    width:80vw;
    margin-left:10vw;
    height: auto;
    .left, .right{
      display: none;
    }
    .leftMobile, .rightMobile, .balls {
      display: initial;
      position: relative;
    }
    .balls{
      height: 18vh;
      left: 2rem;
      position: absolute;
      bottom: 0;
    }
    .leftMobile{
      left: 0;
    }
    .rightMobile{
      top: -0.5vh;
      right: 0;
    }
  }
  @media (min-height:850px) and (max-height:860px) {
    height: auto;
  }
  #content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    padding: 1rem 0;
    flex: 1;
    margin: 0 2rem;
    gap: 2rem;
    span {
      color: #fff;    
      font-family: Roboto, sans-serif;
      font-size: 24px;
      font-weight: 500;
      //padding:2.5vh 4vw 0vh 1vw;
      flex: 2;
      text-align: center;
      
      @media(min-width:900px) and (max-width:1050px){        
        //padding: 0 20vw;
      } 
      @media(min-width:700px) and (max-width:900px){        
        align-items: center;
        display: flex;
        font-size: 20px;
      }         
      @media (max-width:600px) {
        font-size:20px;
        //padding: 2.5vh 7vw;
      }
      @media (max-width:500px) {
        font-size:16px;
        text-align: center;
      }
      @media (max-width:340px) {
        font-size: 14px;      
      }
      @media (min-width:530px) and (max-height:730px) {
        font-size: 1rem;
        padding: 1rem 2rem;
        text-align: center;
      }
      @media (min-height:850px) and (max-height:860px) {
        margin-top: -15vh;
      }
      
    }
    @media(min-width: 0px) and (max-width:1050px){
      flex-direction: column;
    }
    @media (max-width:600px){    
      //flex-direction: column;
      flex-wrap: wrap;
      flex-direction: column;
      #container-buttons__Cookies{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
      }
    }
    
  }
  #container-buttons__Cookies{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    flex: 1;
  }
  .details{
    position: absolute;
    height: 100%;
  }
  
  .right{
    right: 1rem;
    top: 0rem;
    z-index: -1;
  }

  button {    
    color:rgb(226, 210, 210);
    font-weight: 500;
    font: normal normal 500 34px/49px Teko;
    letter-spacing: 0.5px;
    height:8vh;
    padding: 0 3rem;
    border:none;
    border-radius: 10px;
    @media(min-width:900px) and (max-width:1050px){
      padding: 12px 0;
      margin: 1.4vh 0;
      align-self: center;
      width:25vw;
    }
    @media(min-width:700px) and (max-width:900px){       
      font: normal normal 500 26px/40px Teko;
      align-self: center;
      padding: 12px 0;
      margin: 1.4vh 0;
      width: 20vw;
    }
    
    @media (max-width:600px) {
      font-size:20px;
    }

    @media (max-width:470px) {
      font-size:18px;
      align-self: center;      
      display: flex;
      align-items: center;
      justify-content: center;
      width:35vw;
      height: 7vh; 
      margin: 1vh 0;
    }
    @media (min-width:530px) and (max-height:730px) {
      font: normal normal 500 26px/40px Teko;
      align-self: center;
      padding: 1.5rem 3rem;
      margin: 1.8vh 0;
      //width: 20vw;
      display: flex;
      align-items: center;
      justify-content: center;
     }
    @media (max-width:500px) {
      font-size:24px;
    }
    @media (min-height:850px) and (max-height:860px) {
      margin-top: 5vh;
    }
    
  }
 
  #reject {
    background-color: #FF4040;
  }
  #accept {
    background-color: #00C781;
  }
  
}