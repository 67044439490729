#esa-coordenadorias-cientificas-container {
    max-height: 700px;
    overflow: hidden;

    #diretoria-background {
        background-color: #2C627A;
        margin-top: 30px;
        height: 280px;
        width: 100%;
        z-index: -1;
    }

    #diretores {
        width: 60%;
        padding: 2%;
        background-color: #02354D;
        margin-left: 20%;
        position: relative;
        top: -300px;
        display: grid;
        grid-template-columns: 35% 65%;
        color: white;

        .title {
            border-bottom: 2px solid white;
            margin-bottom: 10px;
            font-family: HelveticaNeue-Regular;
        }

        .title-start {
            padding-right: 2%;
        }

        .title-end {
            padding-left: 2%;
        }

        .element-start {
            padding-right: 2%;
        }

        .element-end {
            padding-left: 2%;
        }

        .elements {
            margin-right: 10px;
            margin-bottom: 10px;
            font-weight: 300;
        }

        @media (max-width: 1100px) {
            margin-left: 17%;
            width: 66%;
        }
    }

    @media (max-width: 767.9px) {
        #diretoria-background{
            background: transparent;
        }
        
        #diretores {
            margin-left: 0;
            padding: 50px 2%;
            width: 100%;

            .elements {
                font-size: 13px;
            }
        }
    }
}