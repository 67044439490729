.button-eleicoes {
  margin-top: 16px;
  background-color: #00c781;
  width: 245px;
  min-width: 245px;
  height: 80px;
  border: 0;
  transition: filter 0.3s ease-in-out;
  color: #ffffff;
  font-size: 2.2rem;
  font-family: "teko";
}

.title-eleicoes {
  margin-top: 20px;
  margin-left: 100px;
  margin-bottom: -70px;
}

.container-eleicoes {
  margin-top: 20px;
  margin-left: 100px;
  margin-bottom: 10px;
  @media(max-width: 768px){
    width: 80%;
    margin-left: 10%;
  }
}

.div-eleicoes {
  display: flex;
  flex-direction: row;
  @media(max-width: 768px){
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.editalText{
  list-style: none;
  text-decoration: none;
}

.link-eleicoes {
  margin-top: 45px;
  font-family: "Roboto";
  color: #007bff;
  @media(max-width: 768px){
    display: flex;
    flex-direction: column;
  }
}

.link-eleicoes:link{
  text-decoration: none;
}

.link-eleicoes:hover{
  color:#3E92B7;
}

.text-eleicoes {
  font-family: "Roboto";
  color: #777;
  @media(max-width: 768px){
    display: flex;
    flex-direction: column;
    width: 70%;
  }
}

.text-eleicoes-adv {
  font-family: "Roboto";
  color: #777;
  margin-top: 15px;
  margin-left: 15px;
  @media(max-width: 768px){
    width: 50%;
  }
}

.votantes{
  width: 80%;
  display: flex;
  flex-direction: row;
  @media(max-width: 768px){
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.justificativa{
  margin-left: 15px;
  margin-top: 13px;
  width: 50%;
  @media(max-width: 768px){
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
}
