#esa-diretoria-container {
    #diretoria-background {
        background-color: #2C627A;
        margin-top: 30px;
        height: 280px;
        width: 100%;
        z-index: -1;
    }

    #diretores {
        width: 36%;
        padding: 2%;
        background-color: #02354D;
        margin-left: 15%;
        position: relative;
        top: -300px;
        display: grid;
        grid-template-columns: 65% 35%;
        color: white;

        .title {
            border-bottom: 2px solid white;
            margin-bottom: 10px;
            font-family: HelveticaNeue-Regular;
        }

        .title-start {
            padding-right: 2%;
        }

        .title-end {
            padding-left: 2%;
        }

        .element-start {
            padding-right: 2%;
        }

        .element-end {
            padding-left: 2%;
        }

        .elements {
            margin-bottom: 10px;
            font-weight: 300;
        }

        @media (max-width: 1200px) {
            margin-left: 17%;
            width: 66%;
        }
    }

    @media (max-width: 767.9px) {
        #diretoria-background{
            background: transparent;
        }

        #diretores {
            margin-left: 0;
            padding: 50px 2%;
            width: 100%;
            grid-template-columns: 50% 50%;

            .title-start, .element-start{
                text-align: start;
            }
            
            .title-end, .element-end{
                text-align: end;
            }
        }
    }
}