.rotated {
  transform: rotate(180deg);
}

.slide-style {
  text-align: center;
  color: #3e92b7;
  font-family: Teko;
  font-weight: lighter;
}

.slide-card-style {
  text-align: center;
  color: white;
  background-color: #02354d;
  text-transform: uppercase;
  font-family: Teko;
  font-weight: lighter;
  font-size: 1.6em;
  position: relative;
  bottom: 90px;
  right: 15%;
  width: 387px;
  padding-bottom: 40px;
}
