#section-conselheiro {
  width: 100%;

  .section-1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .container {
      width: 100%;
      max-width: 65%;
      background-color: #02354d;
      padding-top: 40px;
      padding-bottom: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 768px;
      margin-bottom: 50px;
      @media (max-width: 768px) {
        max-width: 90%;
        min-width: 320px;
        margin-bottom: 5rem;
      }
    }
    .titulares-suplentes {
      display: flex;
      flex-direction: row;
      justify-content: center;
      max-width: 100%;
      width: 100%;
      @media (max-width: 768px) {
        flex-direction: column;
        .titulares,
        .suplentes {
          padding: 0 !important;
          text-align: center;
          border-left: none !important;
          display: flex;
          justify-content: center;
        }
      }
    }
    .titulares {
      display: flex;
      text-align: end;
      padding-right: 2rem;
    }
    .suplentes {
      display: flex;
      text-align: start;
      padding-left: 2rem;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
    .conselho-federal {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 85%;
      width: 100%;
      margin-top: 40px;
      span {
        width: 100%;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        font-family: 'Teko';
        font-weight: 400;
        letter-spacing: 0px;
        color: #ffffff;
        font-size: 1.3rem;
        margin-bottom: 11px;
      }
      .suplentes {
        border-left: none !important;
        @media (max-width: 768px) {
          padding: 0 !important;
          text-align: center !important;
          border-left: none !important;
        }
      }
      .titulares {
        text-align: start !important;
        @media (max-width: 768px) {
          padding: 0 !important;
          text-align: center !important;
        }
      }
    }

    ul,
    li {
      list-style: none;
      margin: 0;
      color: white;
    }
    ul {
      text-transform: uppercase;
      font-family: 'Teko';
      font-weight: 400;
      letter-spacing: 0px;
      color: #ffffff;
      font-size: 1.3rem;
      @media (max-width: 768px) {
        padding-top: 20px;
        padding-bottom: 10px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    li {
      transition: all 0.5s ease-in-out;
      text-transform: uppercase;
      font-weight: 300;
      letter-spacing: 0.08px;
      color: #ffffffa2;
      font-size: 1rem;
      cursor: pointer;
      @media (max-width: 550px) {
        font-size: 0.7rem;
      }
    }
    li:hover {
      color: white;
      transform: scaleX(1.1);
      transition: all 0.5s ease-in-out;
      cursor: pointer;
    }
  }
}
