@import './scss/custom.scss';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');

* {
    font-family: 'Roboto', 'Courier New', Courier, monospace;
}
body {
    background: url('./assets/images/BG.svg') 0% 0% repeat padding-box;
    background-size: cover;
    overflow-x: hidden;
}

#footer {
    width: 100%;
    height: 232px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 32px;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #101e38;
    .section-1 {
        display: flex;
        flex-direction: column;
        max-width: 367px;
        width: 35%;
        .title {
            margin: 0px 0 9px 0;
            span {
                letter-spacing: 0.38px;
                color: #ffffff;
                font-size: 15px;
            }
        }
        .grid {
            display: grid;
            grid-template-columns: 0.5fr 1fr;
            grid-gap: 11px;
            p {
                margin: 0;
                letter-spacing: 0.33px;
                color: #ffffff;
                opacity: 0.75;
                font-size: 13px;
            }
        }
    }
    .section-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 10rem;
        span {
            text-align: center;
            letter-spacing: 0px;
            color: #ffffff;
            font-size: 15px;
            padding: 15px 0 24px 0;
            width: 70%;
        }
        .getinfo {
            padding-bottom: 20px;
            display: flex;
            flex-direction: row;
            img {
                padding-right: 11px;
            }
            p {
                margin: 0;
                padding-left: 11px;
                border-left: 2px solid #fff;
                color: #fff;
                text-align: left;
                letter-spacing: 0px;
                font-size: 15px;
            }
        }
    }
    .section-3 {
        width: 20%;
        max-width: 196px;
        padding-right: 1rem;
        .logos {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: flex-end;
        }
    }
}

.link-em-bg-branco{
    font-weight: bold;
    color: #2C627A;
    letter-spacing: 0.4px;
}

.container__link-em-bg-branco a{
    font-weight: bold;
    color: #2C627A;
    letter-spacing: 0.4px;
}

.link-em-bg-azul{
    letter-spacing: 0.5px;
    &:hover{
        opacity: 0.5;
    }
}

.h-full {
    height: 100% !important;
}

.h-half {
    height: 35% !important;
}

.flex {
    display: flex;
    height: 150px;
}
.left-half {
    flex: 1;
}

.right-half {
    flex: 1;
}

img.img-fluid.mt-2 {
    max-width: 60%;
    margin: 0.5rem auto 0 auto;
}

.container-mobile-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
}

// .section-1 {
//   background: url("../../oab-front-end/src/assets/images/Home/BG.svg") 50% 0%
//     repeat padding-box;
//   background-size: cover;
// }

// .box {
//   position: absolute;
//   top: 0;
//   left: 0;
//   opacity: 0.8;
// }
// .stack-top {
//   z-index: 9;
//   margin-top: 500px;
//   margin-left: 250px;
// }

.card_no_border {
    border: 0;
    background-color: transparent;
}

.card-above {
    background-color: #2c627a;
    position: relative;
    margin: auto;
    top: -5%;
    width: 40%;
    height: 45px;
}

.card-top {
    background-color: #2c627a;
    position: relative;
    margin: auto;
    top: -77%;
    width: 40%;
    padding: 40px;
    height: 45px;
}

.card_relative_mobile {
    position: relative;
    height: 100%;
}
.card_relative_mobile img {display: inline-block !important}

.card-body svg {
    fill: rgb(62, 146, 183);
}
.card-body:hover svg {
    fill: #aa4f50;
}

.title_card:hover {
    //filter: invert(0.7) saturate(10);
    .col-3 svg {
        fill: #aa4f50;
    }
    p {
        color: #aa4f50;
    }
}
.title_card {
    .col-3 svg {
        fill: rgb(62, 146, 183);
    }
    p {
        color: rgb(62, 146, 183);
    }
}

.effect-image-1 {
    position: relative;
    display: block;
}

.effect-image-1:hover .simple-overlay-2 {
    visibility: visible;
    opacity: 1;
    width: 100%;
}

.overlay-text {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 96%;
    height: 94%;
    background: rgba(246, 0, 2, 0.7);
}

.overlay-text-1 {
    opacity: 0;
    visibility: hidden;
}

.overlay,
.overlay-text,
.simple-text,
.overlay-sim-text-2,
.overlay-text-effect-3,
.zoom-effect-1 img,
.zoom-effect-2 img,
.opacity-effect img,
.blur-effect img,
.grayscale-effect img {
    transition: all 0.15s linear;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
}
.image-wrapper {
    margin-bottom: 35px;
}
.effect-image-1 {
    position: relative;
    display: block;
}
.overlay-text p,
.overlay-sim-text-2 p,
.overlay-text-effect-3 p {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    padding: 20px 20px;
}
.overlay {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(246, 0, 2, 0.7);
    opacity: 0;
    visibility: hidden;
}

/*-- simple overlay css --*/

.simple-overlay {
    opacity: 0;
    visibility: hidden;
}
.effect-image-1:hover .simple-overlay {
    visibility: visible;
    opacity: 1;
}

/*-- left to right overlay --*/

.simple-overlay-1 {
    width: 0px;
    visibility: hidden;
}
.effect-image-1:hover .simple-overlay-1 {
    visibility: visible;
    opacity: 1;
    width: 100%;
}

/*-- right to left overlay --*/

.simple-overlay-2 {
    width: 0px;
    right: 0;
    left: initial;
}
.effect-image-1:hover .simple-overlay-2 {
    visibility: visible;
    opacity: 1;
    width: 100%;
}

/*-- ovarlay text effect-1 --*/

.overlay-text {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 96%;
    height: 94%;
    background: #3e92b7;
}

.overlay-text-1 {
    opacity: 0;
    visibility: hidden;
}
.effect-image-1:hover .overlay-text-1 {
    opacity: 0.8;
    visibility: visible;
    cursor: pointer;
}
.overlay-text-2 {
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
}
.effect-image-1:hover .overlay-text-2 {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

/*-- overlay text effect 2 --*/

.overlay-sim-text-2 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(246, 0, 2, 0.7);
    opacity: 0;
}

.swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.swiper-slide {
    background-position: center !important;
    background-size: cover !important;
    width: 300px !important;
    height: 300px !important;
}
