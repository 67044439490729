#artigos{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title{
    width: 100%;
    max-width: 992px;
    letter-spacing: 0px;
    color: #02354D;
    text-align: left;
    font-size: 4.2rem;
    font-family: 'teko';
    position: relative;
    font-weight: normal;
    line-height: 1.2;
    @media(max-width:768px){
      font-size: 2.125rem;
      text-align: center;
    }
  }
  .subtitle{
    font-size: 2.125rem;
    padding: 0 !important;
  }
  p{
    font-size: 1rem;
    font-weight: 300;
    font-family: 'Roboto';
    max-width: 447px;
    color: #333333;
  }
  .section-1{
    width: 70%;
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  .section-2::after{
    content: "";
    width: 350px;
    height: 350px;
    background-color: #FF7778;
    opacity: 0.23;
    position: absolute;
    top: -4rem;
    left: -4.5rem;
    z-index: -1;
  }
  .section-2{
    width: 70%;
    display: flex;
    align-items: flex-start;
    text-align: left;
    padding: 80px;
    background-color: #FFFFFF;
    box-shadow: 16px 16px 16px #00000029;
    margin-bottom: 158px;
    margin-top: 136px;
    position: relative;

    .details{
      position: absolute;
      right: -7.5rem;
      top: -8.5rem;
      z-index: -1;
    }

    .title,p{
      padding-left: 35px;
    }
    .container{
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    .escrito{
      margin-top: 199px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      p{
        padding: 0 !important;
        letter-spacing: 0.09px;
        color: #999999;
        font-size: 1.125rem;
        font-weight: normal;
        margin: 0;
      }
    }
    .retangle::after{
      content: "";
      position: absolute;
      width: 14px;
      height: 104px;
      background-color:  #3E92B7;
      left: 0;
      top: 0;
    }
    .button{
      background-color: #00C781;
      width: 245px;
      min-width: 245px;
      height: 80px;
      border: 0;
      transition: filter 0.3s ease-in-out;
      a{
        color: #FFFFFF;
        font-size: 2.2rem;
        font-family: 'teko';
        text-decoration: none;
      }
      &:hover{
        filter: brightness(0.8);
      }
    }
    .carousel .rec .rec-arrow{
      position: absolute;
      bottom: -11rem;
      right: -5rem;
      width:93px;
      height: 46px;
      border-radius: 0;
      background-color: #3E92B7;
      color: #FFFFFF;

      &:hover{
        background-color: #02354D !important;
        box-shadow: 2px 5px 15px #00000040;
      }
      &:disabled{
        background-color: #DADADA;
      }
    }
    .carousel .rec .rec-arrow-left{
      right: 0.8rem;
    }
    .carousel .rec .rec-pagination{
      position: absolute;
      bottom: -11rem;
      left: -5rem;
      width: 100%;
     // max-width: 300px;
    }
    .carousel .rec .rec-dot{
      width: 35px;
      height: 35px;
      background-color: #FF7778;
      border-radius: 0;
      box-shadow:none;
      margin: 0 40px 5px 0;
      opacity:0.8;
    }
    .carousel .rec .rec-dot:last-child{
      margin-right: 0 !important;
    }
    .carousel .rec .rec-dot_active{
      background-color:  #AA4F50;
      opacity: 1;
    }
  }
}

@media (max-width:768px){
  #artigos{
    .section-1{
      width: 90%;
    }
    .section-2::after{
      display: none;
    }
    p{
      text-align: center
    }
    .section-2{
      width: 90%;
      margin-top: 65px;
      padding: 60px 36px  72px 36px;
      .escrito{
        margin-top: 70px;
        flex-direction: column;

        .button{
          margin-top: 34px;
        }
      }
      .title,p{
        padding: 0;
      }
      .retangle::after{
        width: 128px;
        height: 8px;
        left: 35%;
        top: 0;
      }
      .retangle{
        padding-top: 20px;
      }
      .container{
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .carousel .rec .rec-arrow{
        display: none;
      }
      .carousel .rec .rec-pagination{
        left: -3rem;
      }
    }
  }
}
@media (max-width:440px){
 #artigos .section-2 .retangle::after{
   left: 25%;
 }
 #artigos .section-2 {
  .carousel .rec .rec-pagination{
    bottom: -13rem;
    width: 130%;
  }
 }
}