#tabela-honorarios{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .section-1{
    width: 70%;
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  .section-2{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #2C627A;
    margin: 150px 0 241px 0;
    padding: 98px 0 91px 0;
    .color{
      display: flex;
      flex-direction: column;
      margin-left: 3rem;
      background-color: #FFFFFF;
      box-shadow: 16px 16px 16px #00000029;
      padding: 29px 20px 134px 100px;
      position: relative;
    }
    .color::after{
      content: "";
      width: 350px;
      height: 350px;
      background-color: #FF7778;
      opacity: 0.23;
      position: absolute;
      top: -5rem;
      left: -8rem;
    }

    .details{
      position: absolute;
      pointer-events: none;
    }
    .bolinhas5{
      left: -25rem;
      top: 6.5rem;
    }
    .blob5{
      left: -4.5rem;
      bottom: -22rem;
    }
    .bolinhas6{
      right: 3rem;
      bottom: -11.5rem;
    }
    .blob6{
      right: -4rem;
      top: -20rem;
    }

    span{
      text-align: center;
      max-width: 800px;
      letter-spacing: 0px;
      color: #FFFFFF;
      text-transform: uppercase;
      font-size: 3.9rem;
      font-family: 'teko';
      font-weight: normal;
    }
    .button{
      background-color: #00C781;
      width: 245px;
      height: 80px;
      border: 0;
      margin: 32px 0 18px 0;
      transition: filter 0.3s ease-in-out;
      a{
        color: #FFFFFF;
        font-size: 2.2rem;
        font-family: 'teko';
        text-decoration: none;
      }
      &:hover{
        filter: brightness(0.8);
      }
    }
    p{
      letter-spacing: 0.09px;
      color: #FFFFFF;
      font-size: 0.9rem;
      font-weight: 300;
      max-width: 400px;
    }
  }
  .section-2::after{
    content: "";
    width: 350px;
    height: 350px;
    background-color: #FF7778;
    opacity: 0.23;
    position: absolute;    
    margin-top: 30rem;
    left: 20rem;
    z-index: -1;
  }
  .section-2::before{
    content: "";
    width: 350px;
    height: 350px;
    background-color: #FF7778;
    opacity: 0.23;
    position: absolute;
    margin-top: 30rem;
    left: 20rem;
    z-index: -1;
  }
  @media (min-width:700px) and (max-width:1030px){
   .section-2::after { 
      margin-top: 25rem;
      left: 4rem;
    }
    .section-2::before { 
      margin-top: 25rem;
      left: 4rem;
    }
  }
  .redSquare{
    position: relative;
  }
  .redSquare::before{
    content: "";
    width: 350px;
    height: 350px;
    background-color: #FF7778;
    opacity: 0.4;
    position: absolute;
    margin-top: -10rem;
    left: 30rem;
    z-index: -1;
    @media (min-width:700px) and (max-width:1030px){     
      left: 7rem;
      width: 250px;
    }
  }
}

@media(max-width:550px){
  #tabela-honorarios{
    .section-1{
      width: 90%;
    }
    .section-2{
      span{
        font-size: 2.875rem;
        max-width: 320px;
      }
      p{
        max-width: 245px;
      }
      .bolinhas5{
        left: -50rem;
        top: -1rem;
      }
      .blob5{
        height: 680px;
        left: -25rem;
        bottom: -15rem;
      }
      .bolinhas6{
        width: 187px;
        right: 0rem;
        bottom: -8rem;
      }
      .blob6{
        width: 266px;
        right: -3rem;
        top: -8rem;
      }
    }
    .section-2::after{
      width: 259px;
      height: 63px;
      left: 0;
      bottom: -2.5rem;
    }
    .section-2::before{
      width: 216px;
      height: 61px;
      right: 0;
      top: -2.2rem;
    }
  }
}