.slide-active {
    animation-duration: 1s;
    animation-name: slideInResolucao;
}

.slide-out-resolucao {
    animation-duration: 0.5s;
    animation-name: slideOutResolucao;
}

.arrow{
    position: absolute;
    right: -25%;
}

.arrow-mobile{
    display: none;
    position: absolute;
    bottom: -20%;
    right: 0;
}

@media(max-width: 600px){
    .arrow{
        display: none;
    }

    .arrow-mobile{
        display: block;
    }
}

@keyframes slideInResolucao {
    from {
      margin-left: 100%;
    }
  
    to {
      margin-left: 2rem;
    }
}

@keyframes slideOutResolucao {
    from {
      margin-top: -100%;
    }
  
    to {
      margin-top: 2rem;
    }
}
