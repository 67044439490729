#exameOrdem {
  display: flex;
  align-items: center;
  justify-content:center;
  flex-direction: column;
  .row-info{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
  .container-back{
    width: 40%;
    background-color: #02354D;
    padding: 73px 35px;
    color: #fff;
    p{
      text-align: right;
      margin: 3px 0;
    }
  }
  .title h1{
    width: 100%;
    letter-spacing: 0px;
    color: #7A3B3C;
    font-family: 'teko';
    font-size: 11.813rem;
    line-height:0.8;
    font-weight: normal;
    margin-left: 21px;
  }
  .container-mobile{
    background-color: #02354D;
    padding: 27px 29px;
    color: #fff;
    margin: 0 10px;
    p{
      text-align: center;
    }
  }
  span{
    color: rgb(122, 59, 60);
    font-size: 5.25em;
    text-align: left;
    line-height: 1;
    font-weight: normal;
    font-family: 'teko';
  }
}