#editais {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding-bottom: 2rem;
  
    .shade-left,.shade-right {
      position: absolute;
      height:100%;
      top: 0;
    }
    .shade-left{
      left: 0;
    }
    .shade-right{
      right: 0;
    }
    .awssld__container{
      min-height: 100vh;
      background-image: url("../../../assets/publicacoes/cartilhas/mask-bg.png");
      background-size: contain;
    }
  
    .awssld--organic-arrows{
      height: 100%;
      margin-bottom: 2rem;
      --content-background-color: transparent;
      // --organic-arrow-color: #024C65;
    }
    .awssld__controls button{
      height: 60px;
      width: 60px;
      background-color: rgba(0, 0, 0,0.2);
      color: #fff;
      // bottom: 0;
      top: 50%;
      &:hover {
        background: #3E92B7 0% 0% no-repeat padding-box;
      }
    }
    .awssld__controls__arrow-left, .awssld__controls__arrow-right {
      &::before,&::after {
        height: 20px;
        background-color: #FFFFFF;
      }
      &::before{
        top: 0;
      }
    }
    .awssld__controls__arrow-left{
      &::before, &::after {
        left: 0.8rem;
      }
    }
    .awssld__controls__arrow-right{
      &::before, &::after {
        right: 0.8rem;
      }
    }
    .awssld__next{
      right: 2rem;
    }
    .awssld__prev{
      left: 2rem;
    }
    .awssld__bullets{
      width: 75%;
      bottom: -2.5rem;
      justify-content: flex-end;
      z-index: 10;
      display: none;
      button{
        text-indent: initial;
        font-size: 2rem;
        letter-spacing: -0.64px;
        color: #FFFFFF;
        font-family: montserrat-bold;
        height: 50px;
        width: 50px;
        border-radius: 0;
        background-color: #008B74;
      }
    }
    .awssld__bullets .awssld__bullets--active {
      transform: scale(1.1);
      letter-spacing: -0.56px;
      color: #FFFFFF;
      font-size: 1.8rem;
      background-color: #024C65;
    }
  
    .background {
      background: url("") 0% 0% repeat padding-box;
      background-size: cover;
      height: 540px;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
    }
  
  
    .container-banner{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;
      width: 100%;
      max-width: 1088px;
      height: 100%;
      max-height: 500px;
      position: relative;
      @media (max-width: 1300px) {
          max-width: 9
          1100px;
      }
      @media (max-width: 800px) {
          grid-template-columns: 1fr;
          overflow: auto;

          &::-webkit-scrollbar{
            width: 10px;
          }
          &::-webkit-scrollbar-track {
            background-color: #eee;
          }
            
            /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #ccc; 
          }
            
            /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #aaa; 
          }
      }
      .cartilha-item{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 16px 16px 16px #00000029;
        height: 146px;
        display: flex;
        align-items: center;
        text-align: left;
        font: normal normal normal 38px/43px Teko;
        letter-spacing: 0px;
        color: #444444;
        padding: 1rem 5rem 1rem 2rem;
        text-decoration: none;
        background-origin: border-box;
        background-position-x: right;
        background-position-y: bottom;
        background-image: url("../../../assets/publicacoes/cartilhas/mask-ilustration.png");
        @media (max-width: 800px) {
            padding: 2rem 1rem;
            height: max-content;
        }
    }
      .cartilha-item:hover{
        color: white;
        background-color: #3E92B7;
      }
      .info{
        padding: 5rem 3rem;
        background-color: #3E92B7;
        width: 100%;
        height: 100%;
        max-width: 388px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
  
        .retangle{
          position: absolute;
          width: 70%;
          top: 0;
          left: 0;
        }
  
        h1{
          font-size: 2.2rem;
          color: #fff;
          margin-bottom: 0.7rem;
          max-width: 290px;
        }
        span{
          letter-spacing: 0px;
          color: #FFFFFF;
          font-size: 1rem;
          font-family: opensans-regular;
          font-weight: normal;
          margin-bottom: 1rem;
        }
        .assistir{
          margin-top: auto;
          margin-bottom: 2rem;
          max-width: 165px;
          p{
            letter-spacing: 0px;
            color: #FFFFFF;
            font-family: opensans-regular;
            font-weight: normal;
            font-size: 0.8rem;
            margin-bottom: 0.7rem;
          }
          button{
            background: #024C65;
            border-radius: 4px;
            height: 50px;
            letter-spacing: 0px;
            color: #D7EE58;
            text-transform: uppercase;
            font-size: 1.125rem;
            font-family: opensans-bold;
            border: 0;
            width: 100%;
            transition: background 0.2s;
  
            &:hover {
              background: #292E30;
            }
          }
        }
      }
      .banner{
        width: 100%;
        max-width: 700px;
        height: 100%;
        background-color: #f7f7f7;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 60%;
          height: 50%;
        }
      }
      .numeracao{
        position: absolute;
        bottom:0;
        left:1.5rem;
        letter-spacing: 0.58px;
        color: #FFFFFF;
        font-size: 4.5rem;
        font-family: montserrat-bold;
        z-index: 2;
      }
      .barra{
        position: absolute;
        width: 115px;
        height: 50px;
        left: -3rem;
        bottom: 0;
        background-color: #024C65;
      }
    }
  }
  
  @media (max-width: 1090px) {
    #editais{
      .awssld__next{
        right: 0rem;
      }
      .awssld__prev{
        left: 0rem;
      }
      .container-banner {
        max-width: 90%;
        max-height: 600px;
        .info{
          max-width: 300px !important;
          h1{
            font-size: 1.5rem ;
          }
          span {
            font-size: 0.8rem ;
          }
        }
        .banner img {
          width: 50% !important;
          height: 40% !important;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    #editais {
      .container-banner {
        margin-top: 5rem;
        flex-direction: column-reverse;
        width: 80%;
        max-width: 100%;
  
        .info{
          width: 100%;
          max-width: 100% !important;
        }
        .banner {
          height: 329px !important;
          img {
            height: 100% !important;
            width: 100% !important;
          }
        }
      }
    }
  }
  @media (max-width: 520px) {
    #editais{
      .awssld__controls{
        visibility: visible;
      }
  
      .info {
        max-height: 481px;
        padding: 1rem 2rem !important;
        align-items: center;
        justify-content: center;
        h1 {
          font-size: 1rem !important;
        }
        span {
          font-size: 0.7rem !important;
          text-align: center;
        }
      }
      .assistir{
        p{
          text-align: center;
        }
      }
      .retangle {
        display: none;
      }
      .numeracao{
        font-size: 2.2rem !important;
        bottom: 1rem !important;
      }
      .barra{
        width: 67px !important;
        height: 40px !important;
        left: 0 !important;
      }
    }
  }