.title-menu {
  letter-spacing: 0px;
  font-size: 35px !important;
  color: #aa4f50 !important;
  overflow: hidden;
  position: relative;
  z-index: 3;
  font-family: 'Teko';
  font-weight: 400;
  @media (max-width: 550px) {
    color: #fff !important;
    width: 100%;
    justify-content: center;
    height: 40px;
    font-size: 26px !important;
    padding: 0 !important;
  }
}

.textMenu{
  font-size: 25px;
  margin-top: 20px;
  font-weight: bold;
  font-family: Teko;
}
.semborda{
  width: 0;
  position: absolute;
  right:0;
  height: 26px;
  background-color: #FF7778;
  opacity: 0.5;
  z-index: -1;
  transition: width 0.5s ease-in-out;
}
.borda{
  width: 54px;
}

.border {
  border: 1px solid #fff;
  width: 250px;
  position: absolute;
  bottom: 2px;
  z-index: 10;
  opacity: 0;
  transition: 1s;
}
.border-show {
  transition: 1s;
  opacity: 1 !important;
}
.background {
  @media (max-width: 550px) {
    background-color: #02354d;
  }
}
.link-menu {
  letter-spacing: 0px;
  font-size: 35px;
  color: #aa4f50 !important;
  font-family: 'Teko';
  font-weight: 400;
  @media (max-width: 550px) {
    color: #fff !important;
    height: 40px;
    font-size: 26px;
    padding: 0 !important;
  }
}
.link-menu:hover {
  text-decoration: none !important;
}

.link {
  line-height:1;
  letter-spacing: 0px;
  color: #444444 !important;
  font-size: 24px;
  font-family: 'Teko';
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 550px) {
    color: #fff !important;
    font-size: 18px;
  }
}
.link:hover {
  text-decoration: underline !important;
}
.subtitles.subtitles {
  margin-top: 7px;
  overflow: hidden;
  text-decoration: none !important;
  @media (max-width: 550px) {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
}
.container-subtitles {
  align-items: flex-end !important;
  text-align: right;
  overflow: hidden;
  transition: 1s;
  height: 0;
  opacity: 0;
  @media (max-width: 550px) {
    text-align: center !important;
    align-items: center !important;
    box-shadow: 0px 2px 5px #00000029;
  }
}
.container-show {
  align-items: flex-end !important;
  text-align: right;
  transition: 1s;
  height: 220px;
  opacity: 1;
  overflow: hidden;
  @media (max-width: 550px) {
    text-align: center;
    align-items: center !important;
    background-color: #02354d;
    height: 210px;
    padding: 0 0 10px 0;
  }
}
.container-subtitles-2 {
  align-items: flex-end !important;
  text-align: right;
  overflow: hidden;
  transition: 1s;
  height: 0;
  opacity: 0;
  @media (max-width: 550px) {
    text-align: center !important;
    align-items: center !important;
    box-shadow: 0px 2px 5px #00000029;
  }
}
.container-show-2 {
  align-items: flex-end !important;
  text-align: right;
  transition: 1s;
  height: 220px;
  opacity: 1;
  overflow: hidden;
  @media (max-width: 550px) {
    text-align: center;
    align-items: center !important;
    background-color: #02354d;
    height: 210px;
    padding: 0 0 10px 0;
  }
}
.container-subtitles-3 {
  align-items: flex-end !important;
  text-align: right;
  overflow: hidden;
  transition: 1s;
  height: 0;
  opacity: 0;
  @media (max-width: 550px) {
    text-align: center !important;
    align-items: center !important;
    box-shadow: 0px 2px 5px #00000029;
  }
}
.container-show-3 {
  align-items: flex-end !important;
  text-align: right;
  transition: 1s;
  height: 220px;
  opacity: 1;
  overflow: hidden;
  @media (max-width: 550px) {
    text-align: center;
    align-items: center !important;
    background-color: #02354d;
    height: 210px;
    padding: 0 0 10px 0;
  }
}
.container-titles {
  transition: 1s;
  height: 497px;
  justify-content: space-between;
  margin-top: 35px;

  @media (max-width: 550px) {
    height: 300px;
    margin-top: 0;
  }
}

.container-titles-show {
  transition: 1s;
  height: 300px !important;
  margin-top: 0px;
  justify-content: space-between;
  @media (max-width: 550px) {
    opacity: 0.5;
    margin: 0;
  }
}

.menu {
  transition: all 400ms ease-in-out;
  top: 80px;
  right: 0rem;
  opacity: 0;
  pointer-events: none;
  z-index: 9998;

  @media (max-width: 550px) {
    left: -5rem !important;
    top: 0px;
    width: 297px !important;
  }
}
.menu-show {
  transition: all 400ms ease-in-out;
  top: 80px;
  right: 5rem;
  opacity: 1;
  pointer-events: visible;
  z-index: 21;
  @media (max-width: 1800px) {
    right: 3rem;
  }
  @media (max-width: 550px) {
    left: 0rem !important;
    top: 0px;
    right: 0px;
    width: 297px !important;
  }
}
.menu-icon{
  margin-left: 8px;
  @media (max-width:550px){
    margin-left: 0;
    font-size: 2rem;
  }
}
.rotate-menu{
  transform: scaleX(-1) rotate(90deg);
}
.icon-submenu i{
  transition: 0.5s;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 8px;
  z-index: 1;
}
.icon-title i {
  transition: 0.5s;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 17px;
  z-index: 1;
}
.rotate i {
  transition: 0.5s;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 17px;
  transform: rotate(90deg);
}

.search {
  display: flex;
  flex-direction: row;
  width: 70%;
  overflow: hidden;
  width: 100%;
  max-width: 250px;
  display: flex;
  justify-content: space-between;
  right: 2rem;
}
.search i {
  transition: 1s;
  color: black;
  font-size: 1.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  @media (max-width: 550px) {
    color: #ffffff;
  }
}
.search i:hover {
  transition: 1s;
  margin-left: 0.5rem;
  @media (max-width: 550px) {
    margin-left: 0;
    left: 4rem;
  }
}

.input.input {
  transition: 1s;
  width: 0;
}
.show-input {
  transition: 1s;
  width: 200px !important;
}
.icon-subtitle {
  display: flex;
  align-items: center;
}

.icon-subtitle i {
  font-size: 0.8rem;
  color: #fff;
  margin-right: 10px;
}

.icons {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0.5rem;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

@media (max-width: 550px) {
  .title-hover {
    background-color: #02354d;
    height: 40px;
    width: 100%;
    box-shadow: 0px 2px 5px #00000029;
    z-index: 2;
  }
  .logo {
    text-align: center !important;
  }
  .search {
    margin: 20px 0;
    justify-content: flex-end;
  }

  .opacity{
    opacity: 0;
    pointer-events: none;
  }
  .open-menu-mobile {
    position: relative;
    transition: all 2s ease;
  }

  .open-menu-mobile-show {
    transition: all 2s ease;
    position: absolute !important;
    right: 0;
    top: 0;
    background-color: white;
    z-index: 22;
  }
}

@keyframes Show {
  0%{
    width: 0;
  }
  100%{
    width: 74px;
  }
}
