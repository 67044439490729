#BuscaGeral{
    #section-1__BuscaGeral, #section-2__BuscaGeral{
        margin: 0 auto;
        width: 80%;
    }
    #section-busca__BuscaGeral{
        margin: 0 auto 3rem;
        width: 80%;
        display: flex;
        align-items: center;
        i{
            font-size: 25px;
            margin-right: 0.5rem;
        }
        #input-search{
            height: 44px;
            box-shadow:  inset 0 0 1em #00000029, 0 0 1em #00000029;
            background-color: #ffffff;
            overflow: hidden;
            border: none;
            border-style: hidden;
            border-color: white;
            width: 50%;
        }
    }
    #section-2__BuscaGeral{
        padding: 0 0 5rem;
        display: grid;
        grid-template-columns: 3fr 1fr;
        h2{
            font-size: 2.1rem;
            color: #2c627a;
            font-family: "Teko";
            font-weight: 400;
            letter-spacing: 0px;
            text-align: left;
            text-transform: uppercase;
        }
        a{
            font-family: "Teko";
            font-weight: 400;
            letter-spacing: 0px;
            color: #999999;
            font-size: 1.6rem;
            margin: 0;
            text-align: left;
        }
        .newsIndividual {
            width: 100%;
            max-width: 750px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0;
            padding: 0 1rem;
            @media (min-width: 1280px) {
                padding: 0 1.5rem;
            }
            a {
                text-decoration: none;
                width: 100%;
                max-width: 750px;
                margin: 1rem 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                gap: 1rem;
                flex: 1;
                @media (max-width: 520px) {
                    display: grid;
                }
                .newsIndividualText {
                    width: 100%;
                    flex: 1;
                }
                .newsIndividualImg {
                    background-size: cover;
                    background-repeat: no-repeat;
                    height: 200px;
                    flex: 2;
                    @media (min-width: 1280px) {
                        max-width: 350px;
                        height: 250px;
                    }
                    @media (max-width: 520px) {
                        height: auto;
                        width: 80vw;
                    }
                }
                .newsIndividualText {
                    p {
                        font-size: 1.1rem;
                        margin: 0;
                        color: black;
                        text-align:justify;
                        @media (max-width: 1280px) {
                            font-size: 0.9rem;
                        }
                        &:nth-child(2) {
                            font-size: 1rem;
                            letter-spacing: 1.5px;
                            color: #dadbdb;
                        }
                        &:nth-child(3) {
                            color: #b0b0b3;
                        }
                    }
                }
            }
        }
    }
}