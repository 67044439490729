#piso-salarial{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .section-1{
    width: 70%;
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  .section-2::after{
    content: "";
    width: 350px;
    height: 350px;
    position: absolute;
    left: -4.5rem;
    top: -2rem;
    background-color: #FF7778 ;
    opacity: 0.23;
    z-index: -1;
  }
  .section-2{
    position: relative;
    margin-top: 64px;
    margin-bottom: 62px;
    width: 70%;
    padding: 80px 160px 73px 115px;
    background-color: #fff;
    box-shadow: 16px 16px 16px #00000029;
    span::after{
      content: "";
      width:14px;
      height: 104px;
      background-color: #3E92B7;
      position: absolute;
      left: -2rem;
      top: -.5rem;
    }
    span{
      position: relative;
      letter-spacing: 0px;
      color: #02354D;
      font-size: 4.2rem;
      font-family: 'teko';
      text-align: left;
      strong{
        color: #FF4040;
        font-weight: normal;
        font-family: 'teko';
      }
    }
  }
  .section-3{
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 110px;
    position: relative;
    min-height: 414px;

    .show{
      height: 142px !important;
      opacity:1 !important;
      pointer-events: visible !important;
    }
    .arquivos{
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2C627A;
      height: 0;
      opacity: 0;
      pointer-events: none;
      padding: 0 224px;
      transition: all 0.5s ease-in-out;
      .title-arquivo a{
        letter-spacing: 0px;
        color: #FFFFFF;
        font-size: 1.55rem;
        font-family: 'teko';
        text-decoration: none;
      }
    }

    .selecione{
      width: 35%;
      display: flex;
      justify-content: flex-end;
      margin-right: 50px;
      .fechar{
        display: none !important;
      }
      .container-selecione{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2C627A;
        box-shadow: 0px 5px 15px #00000040;
        position: relative;
        text-align: center;
        height: 400px;
        width: 361px;
        min-width: 300px;
        span{
          letter-spacing: 0px;
          color: #FFFFFF;
          font-size: 3.9rem;
          font-family: 'teko';
        }
        .Arrow{
          position: absolute;
          right: -3.5rem;
          bottom: 50%;
          top: 40%;
        }
      }
    }
    .cards{
      width: 40%;
      display:grid;
      grid-template-columns: repeat(auto-fill, minmax(203px,1fr));
      justify-items: center;

      .active{
        background-color: #2C627A !important;
        transform: scale(1.1);
        z-index: 10;
        .title-card{
          color: #FFFFFF !important;
        }
      }
      .container-card{
        width: 203px;
        height: 203px;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        transition: all 0.5s ease-in-out;
        .title-card{
          letter-spacing: 0px;
          color: #2C627A;
          text-transform: uppercase;
          font-size: 1.3rem;
          font-family: 'teko';
          max-width: 100px;
          text-align: center;
        }
        &:hover{
          background-color: #3E92B7;
          transform: scale(1.1);
          z-index: 10;
          .title-card{
            color: #FFFFFF !important;
          }
        }
      }
    }
  }
}

@media(max-width:1700px){
  #piso-salarial{
    .section-3{
      width: 70%;
    }
  }
}
@media(max-width:1310px){
  #piso-salarial{
    .section-3{
      .arquivos{
        padding: 0 120px;
      }
    }
  }
}
@media(max-width:950px){
  #piso-salarial{
    .section-3{
      flex-direction: column;
      min-height: 654px;
      .arquivos{
        width: 100%;
      }
      .selecione,.cards{
        width: 100%;
      }
      .selecione{
        justify-content: center;
        margin-bottom: 28px;
        .container-selecione{
          width: 300px;
          height: 220px;
          span{
            font-size: 2.5rem;
          }
          .Arrow{
            display: flex;
            width: 80px;
            height: 60px;
            -moz-transform: scaleX(-1) rotate(90deg);
            -o-transform: scaleX(-1) rotate(90deg);
            -webkit-transform: scaleX(-1) rotate(90deg);
            transform: scaleX(-1) rotate(90deg);
            right: 0;
            top: 85%;
           }
        }
      }
    }
  }
}

@media(max-width:580px){
  #piso-salarial{
   .section-1,.section-2, .section-3{
     width: 90%;
   }
   .section-2::after{
    display: none;
   }
   .section-2{
     padding: 69px 36px 43px 37px;
     display: flex;
     justify-content: center;
     span{
       font-size: 2.125rem;
       text-align: center;
     }
     span::after{
       width: 128px;
       height: 8px;
       left: 30%;
       top: -2rem;
     }
   }
   .section-3{
     .arquivos{
       padding: 0;
     }
   }
  }
}
@media(max-width:451px){
  #piso-salarial{
   .section-3{
     min-height: 1132px;
     .show{
       height: 78px !important;
     }
     .Arrow{
       top: 90% !important;
       width: 44px !important;
       height:40px !important;
     }
     .container-selecione{
       width: 188px !important;
       height: 212px !important;
       min-width: 188px !important;
     }
     .container-card{
       width: 90% !important;
       margin: 10px 0;
     }
     .title-card{
       max-width: 236px !important;
     }
   }
  }
}