#ouvidoria {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .section-1{
    width: 70%;
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  .section-2{
    width: 70%;
    margin-top: 30px;
    margin-bottom: 118px;
    .container-form::after{
      content: "";
      width: 228px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #3E92B7;
      z-index: 1;
    }
    .container-form::before{
      content: "";
      width: 250px;
      height: 250px;
      position: absolute;
      right: -7rem;
      top: 35%;
      background-color: #FF7778 ;
      opacity: 0.23;
      z-index: -1;
    }
    .container-form{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      text-align: left;
      background-color: #FFFFFF;
      box-shadow: 16px 16px 16px #00000029;
      padding: 82px 104px 116px 0;
      position: relative;

      .container-contact::before{
        content: "";
        width: 70px;
        height: 70px;
        position: absolute;
        background-color: #FF7778;
        opacity: 0.23;
        top: -2.3rem;
        right: -2.3rem;
      }
      .container-contact > span:first-child::before{
        content: "";
        width: 104px;
        height: 14px;
        background-color: #3E92B7;
        position: absolute;
        top: -1.5rem;
      }
      .container-contact{
        width: 40%;
        min-width: 400px;
        margin-right: 2.3rem;
        padding: 86px;
        display: flex;
        flex-direction: column;
        background-color: #02354D;
        z-index: 2;
        position: relative;
        span,a{
          letter-spacing: 0px;
          color: #FFFFFF;
          font-size: 1.5rem;
          font-family: 'Roboto';
          line-height: 1.2;
          position: relative;
        }
        a{
          color:  #3E92B7;
          line-height: 2;
        }
        p{
          letter-spacing: 0.08px;
          color: #FFFFFF;
          font-size: 1rem;
          font-weight: 300;
          margin-top: 2rem;
        }
      }

      .form{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .button{
          margin-top: 16px;
          background-color: #00C781;
          width: 245px;
          min-width: 245px;
          height: 80px;
          border: 0;
          transition: filter 0.3s ease-in-out;
          color: #FFFFFF;
          font-size: 2.2rem;
          font-family: 'teko';
          &:hover{
            filter: brightness(0.8);
          }
        }
      }
    }
  }
}

@media (max-width:1000px){
  #ouvidoria{
    .section-2 .container-form::after{
      display: none;
    }
    .section-2 .container-form{
      flex-direction: column;
      padding: 0 20px 20px 20px;
      .container-contact{
        width: 100%;
        margin: 0;
      }
      .form{
        align-items: center;
        width: 100%;
        z-index: 4;
      }
    }
  }
}
@media (max-width:1440px){
  #ouvidoria{
    .section-1,.section-2{
      width: 90%;
      .container-form::before{
        display: none;
      }
      .container-contact::before{
        display: none;
      }
      .container-contact{
        min-width: 0px !important;
        span,a,p{
          text-align: center;
        }
        span,a{
          font-size: 1rem !important;
        }
      }
    }
  }
}
@media (max-width:475px){
  #ouvidoria{
    .section-2 .container-form .container-contact {
      padding:68px 30px;
    }
    .container-contact > span:first-child::before{
      left: 3.5rem;
    }
  }
}