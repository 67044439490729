#CarrosselNoticias{
#veja-tambem-container{
    display: flex;
    flex: 1;
    justify-content: flex-start;
    width: 80vw;
    transform: translateX(0%);
    margin: 0 auto;
    align-items: center;
    border-bottom: 1px solid #dadada;
    div{
        width: 8px;
        height: 34px;
        background: #3E92B7 0% 0% no-repeat padding-box;
        margin-right: 1rem;
        @media (max-width: 520px) {
            width: 6px;
            height: 25px;
            margin-right: 0.5rem;
        }
    }
    p {
        text-align: left;
        font: normal normal normal 38px/54px Teko;
        letter-spacing: 0.57px;
        color: #444444;
        width: 100%;
        margin: 0;
        align-items: center;
        display: flex;
        @media (max-width: 520px) {
            font-size: 25px;
        }
    }
}
.carrossel {
    width: 80%;
    margin: 2rem auto;
    height: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1250px) {
        flex-direction: column;
        width: 100vw;
    }
    .nomobile{
        @media (max-width: 1250px) {
            display: none !important;
        }
    }
    .arrowLeft {
        display: flex;
        margin-right: 1rem;
        font-size: 2rem;
        height: 350px;
        background: white;
        align-items: center;
        padding: 1rem;
        z-index: 10;
        position: absolute;
        box-shadow: 20px 0px 20px #fff;
        left: 0%;
        padding-left: 5%;
        @media (max-width: 1250px) {
            height: auto;
            padding: 0 !important;
            box-shadow: transparent;
            background: transparent;
            position: relative;
        }
        svg {
            border: 1px solid #ff7778;
            &:hover {
                background-color: #02283a;
                color: white;
            }
        }
    }
    .arrowRight {
        display: flex;
        justify-content: flex-start;
        margin-left: 1rem;
        font-size: 2rem;
        background: white;
        align-items: center;
        right: 0%;
        z-index: 10;
        padding-right: 5%;
        padding-left: 1rem;
        position: absolute;
        box-shadow: -20px 0px 20px #fff;
        height: 350px;
        @media (max-width: 1250px) {
            height: auto;
            padding: 0 !important;
            box-shadow: transparent;
            background: transparent;
            position: relative;
        }
        svg {
            border: 1px solid #ff7778;
            &:hover {
                background-color: #02283a;
                color: white;
            }
        }
    }
    #animationRight{
        animation: animation-right 0.8s forwards;
    }

    #animationLeft{
        animation: animation-left 0.8s forwards;
    }
    .pagination{
        display: flex;
        margin: 1rem auto;
        @media (min-width: 1250px) {
            display: none;
        }
        .pagesNumbersMobile {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 2.5rem;
            display: none;
            p {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Roboto';
                font-size: 1rem;
                // padding: 0.7rem;
                //margin: 1rem;
                background-color: #ff7778;
                color: #02283a;
                transition: all 0.1s ease-out;
                height: 47px;
                width: 47px;
                margin: 0;
                &:hover {
                    cursor: pointer;
                    font-size: 28px;
                    background-color: #02283a;
                    color: white;
                    height: 57px;
                    width: 57px;
                    margin: 0 -5px;
                }
            }
        }
    }
    .pagesNumbers {
        display: flex;
        width: 100%;
        position: relative;
        z-index: 1;
        align-items: center;
        overflow-x: hidden;
        overflow-y: hidden;
        transform: translateX(0);
        left: auto;
        margin: auto;
        width: 69%;
        scroll-behavior: smooth;
        @media (max-width: 1250px) {
            width: 100vw;
        }
        @media (min-width: 1300px) {
            max-width: 1200px;
        }
        @media (min-width: 1450px) {
            max-width: 1120px;
        }
        @media (min-width: 1600px) {
            max-width: 1080px;
        }
        @media (min-width: 1700px) {
            max-width: 1000px;
        }
        @media (min-width: 1900px) {
            max-width: 900px;
        }
        p {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Roboto';
            font-size: 1rem;
            background-color: #F2F2F2;
            color: #02354D;
            transition: all 0.1s ease-out;
            text-align: left;
            font: normal normal normal 25px/26px Teko;
            letter-spacing: 0px;
            height: 300px;
            width: 300px;
            margin: 0;
        }
    }
    .noticia {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 300px;
        width: 300px;
        margin: 0 0.5rem;
        display: flex;
        &:hover{
            animation: Up 0.8s forwards;
        }
        div {
            height: 50%;
            height: 50%;
            background-size: cover;
            width: 100%;
            background-repeat: no-repeat;
            background-position: center;
            align-items: flex-end;
            display: flex;
            color: white;
            p{
                color: white;
                width: 100%;
                height: 80%;
                padding: 0 1rem;
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                background: transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%) 0% 0% no-repeat padding-box;
                text-align: left;
                font: normal normal normal 16px/24px Roboto;
                letter-spacing: 0.08px;
            }
        }
        .title {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            font-family: 'Roboto';
            font-size: 1rem;
            transition: all 0.1s ease-out;
            margin: 0;
            padding: 1rem;
            min-height: 50%;
            text-align: left;
            font: normal normal normal 25px/26px Teko;
            letter-spacing: 0px;
            flex-direction: column;
            div{
                width: 44px;
                height: 7px;
                background: #3E92B7 0% 0% no-repeat padding-box;
                margin-bottom: 1rem;
                @media (max-width: 520px) {
                    width: 44px;
                    height: 7px;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}
}
@keyframes Up {
    0% {
      margin-bottom: 0rem;
    }
    100% {
      margin-bottom: 2rem;
    }
  }

  @keyframes animation-right {
    from {
        left: 0%;
    }
    to {
        right: 0%;
    }
  }

  @keyframes animation-left {
    from {
        right: 0%;
    }
    to {
        left: 0%;
    }
  }