#banco-do-brasil{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1{
    margin: 0;
  }
  a{
    text-decoration: none;
  }
  .title{
    width: 100%;
    max-width: 992px;
    letter-spacing: 0px;
    color: #02354D;
    text-align: left;
    font-size: 4.2rem;
    font-family: 'teko';
    position: relative;
    font-weight: normal;
  }
  p{
    max-width: 797px;
    text-align: left;
    letter-spacing: 0.09px;
    color: #FFFFFF;
    font-size: 1.125rem;
  }

  .section-1{
    width: 70%;
    display: flex;
    align-items: flex-start;
  }
  .section-2{
    margin-top: 115px;
    width: 70%;
    display: flex;
    align-items: flex-start;

    .title::before{
      content:"";
      position: absolute;
      height:124px;
      width:225px;
      background-color:#FF7778 ;
      opacity: 0.23;
      top: -3rem;
      left: -4rem;
    }
  }
  .section-3{
    margin-bottom: 163px;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:row;
    position: relative;
    .bolinhas2-externo{
      position: absolute;
      right: -7rem;
      top: -12rem;
      pointer-events: none;
    }

    .blue,.red{
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: relative;
    }
    .blue{
      padding: 103px 85px;
      background-color: #02354D;
      box-shadow: 13px 13px 15px #00000033;
      overflow: hidden;

      .details{
        position: absolute;
        pointer-events: none;
      }
      .blob3{
        left: -20rem;
        bottom: -15rem;
      }
      .blob4{
        right: -22rem;
        top: -22rem;
      }
      .bolinhas2{
        width:166px;
        height: 184px;
        bottom: -6.5rem;
        right: 5rem;
      }
    }
    .red{
      background-color: #FF7778;
      padding: 85px;
    }
  }
  .section-4{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #02354D;
    padding: 66px 0;
    position: relative;
    overflow: hidden;
    .details{
      position: absolute;
      pointer-events: none;
    }
    .blob4{
      left: -10rem;
    }
    .bolinhas2{
      right: 0;
      bottom: -7rem;
    }
    .column{
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: relative;
    }
    .column::before{
      content:"";
      position: absolute;
      height:134px;
      width:322px;
      background-color:#FF7778 ;
      opacity: 0.23;
      top: -8rem;
      right: 0rem;
    }
    .button{
      background-color: #00C781;
      width: 100%;
      max-width: 185px;
      height: 46px;
      border: none;
      outline: none;
      transition: filter 0.2s ease-in-out;
      &:hover{
        filter: brightness(0.7);
      }
      a{
        color: #FFFFFF;
        font-family: 'teko';
        font-size: 1.55rem;
      }
    }
  }
  .section-5{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 73px;
    position: relative;
    .details{
      position: absolute;
      pointer-events: none;
    }
    .bolinhas4{
      left: -14rem;
      top: 14rem;
    }
    p{
      width:50%;
      margin: 0;
    }
    .subtitle{
      font-size: 2.125rem;
      margin-left: 61px;
      width:50%;
      color: #FFFFFF;
    }
    .mini-title{
      margin-top: 34px;
      font-size: 1.55rem;
      max-width: 600px;
    }
    .mini-title::before{
      content: "";
      height: 28px;
      width: 109px;
      position: absolute;
      bottom: -1.5rem;
      left: 0;
      background-color:  #FF7778;
    }
    .documentos{
      width: 100%;
      background-color: #3E92B7;
      box-shadow: 13px 13px 15px #00000033;
      display: flex;
      flex-direction: row;
      padding:85px;
      position: relative;
    }
    .documentos::after{
      content:"";
      position: absolute;
      height:104px;
      width:322px;
      background-color:#FF7778 ;
      opacity: 0.23;
      top: -6rem;
      left: 7.5rem;
      z-index: -1;
    }
    .documentos::before{
      content:"";
      position: absolute;
      height:78px;
      width:322px;
      background-color:#FF7778 ;
      opacity: 0.23;
      bottom: -2.5rem;
      right: -3rem;
      z-index: -5;
    }
    .docs{
      width: 45%;
      // max-width:300px ;
      display: flex;
      flex-direction:row;
      position: absolute;
      bottom: -2rem;
      right:47.7%;
      left: 52.3%;
      .active{
        background-color: #02354D !important;
        transform: scale(1.1);
      }
      .active::before{
        background-color: #02354D !important;
      }
      .doc{
        width: 64px;
        height: 69px;
        background-color: #FF7778;
        display: flex;
        align-items: center;
        justify-content:center;
        margin-right: 28px;
        letter-spacing: 0.2px;
        color: #FFFFFF;
        font-size: 2.5rem;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        position: relative;
        z-index: 4;
        &:hover{
          background-color: #02354D;
          box-shadow: 6px 6px 6px #00000033;
          transform: scale(1.1);
        }
      }
      .doc::before{
        content: "";
        position: absolute;
        height: 20px;
        width: 31px;
        top: -0.25rem;
        right: 0;
        background-color: #FF7778;
        border-radius: 5px;
        transition: all 0.2s ease-in-out;
      }
      .doc:hover::before{
        background-color: #02354D;
      }
    }
    .content{
      background-color: #FFFFFF;
      box-shadow: 16px 16px 14px #00000029;
      position: absolute;
      z-index:3;
      display: flex;
      flex-direction: column;
      p{
        color: #02354D;
        width: 100%;
      }
    }
    .doc-1{
      width: 30%;
      right: 16%;
      padding: 89px 20px;
      animation: Queda 0.5s forwards;
    }
    .doc-2{
      width: 50%;
      right: -10%;
      padding: 20px 10px;
      animation: Queda2 0.5s forwards;
      p{
        width: 80%;
      }
    }
    .doc-3{
      width: 50%;
      right: -18%;
      padding: 42px 20px;
      animation: Queda3 0.5s forwards;
      p{
        margin-bottom: 20px;
      }
      a{
        text-decoration: none;
        letter-spacing: 0px;
        color: #999999;
        font-size: 1.3rem;
        font-family: 'teko';
        font-weight: normal;
        cursor: pointer;
        transition: color 0.3s ease-in-out;
        &:hover{
          color: #3E92B7;
        }
      }
    }
  }
  .section-6{
    margin-top: 50px;
    margin-bottom: 50px;
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #FF7778;
    padding: 85px;
    position: relative;
    z-index:-2;
    .details{
      position: absolute;
      pointer-events: none;
    }
    .bolinhas3{
      right: 35%;
      top: 4rem;
    }
    .subtitle{
      color: #FFFFFF;
      font-size: 2.125rem;
      text-align: center;
    }
    .pagamentos{
      margin-right: 43px;
    }
    .atendimento{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #02354D;
      padding: 78px 102px;
      overflow: hidden;
      position: relative;
      p{text-align: center;}

      .blob4{
        width: 289px;
        height:280px;
        left: -9rem;
      }
      .blob3{
        right: -12rem;
        bottom: -23rem;
      }
    }
  }
  .section-6::before{
    content: "";
    background-color: #FF8A8B;
    width: 40%;
    height: 100%;
    position: absolute;
    right: 0;
    z-index: -1;
  }
}

@keyframes Queda{
  0%{
    bottom: -5rem;
  }
  100%{
    bottom: -18rem;
  }
}
@keyframes Queda2{
  0%{
    bottom: -5rem;
  }
  100%{
    bottom: -13rem;
  }
}
@keyframes Queda3{
  0%{
    bottom: -5rem;
  }
  100%{
    bottom: -26rem;
  }
}

@media(max-width:1100px){
  #banco-do-brasil{
    .section-3{
      .blue,.red{
        padding: 40px;
      }
    }
  }
}

@media(max-width:900px){
  #banco-do-brasil{
    .section-3{
      width: 100%;
      flex-direction: column;
      .bolinhas2-externo{
        display: none;
      }
      .blue,.red{
        width: 90%;
        padding: 103px 85px; 
      }
      .blue{
        width: 80%;
        position: relative;
        margin-bottom: -3rem;
        .bolinhas2{
          bottom: -2rem;
        }
      }
      .red{
        p,h1{
          text-align: center;
        }
      }
    }
    .section-5 {
      width: 90%;
      .documentos{
        flex-direction: column;
        p,.subtitle{
          width: 100%;
          text-align: center;
        }
        .subtitle{
          margin: 0;
          margin-top: 37px;
        }
        .docs{
          width: 70%;
          left: 30%;
        }
        .doc-1,.doc-2,.doc-3{
          left: 0;
          width: 100%;
        }
      }
      .documentos::before{
        display: none;
      }
    }
    .section-6{
      width: 100%;
      flex-direction: column;
      position: relative;
      background-color: transparent;
      padding: 0;
      .pagamentos{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #FF7778;
        margin: 0;
        padding: 40px;
      }
      .atendimento{
        padding: 30px 59px;
        width: 100%;
      }
      span,p{
        text-align: center;
      }
    }
    .section-6::before{
      display: none;
    }
  }
}
@media(max-width:550px){
  #banco-do-brasil{
    .section-1{
      width: 90%;
    }
    .section-3{
      margin-bottom: 71px;
      .blue,.red{
        padding: 40px;
        h1{
          margin-top: 2.5rem;
        }
      }
      .red{
        width: 100%;
      }
      .blue{
        width: 95%;
      }
    }
   .title{
     font-size: 2.125rem;
   }
  .section-2{
    width: 90%;
    margin-top: 76px;
    .title::before{
    width: 225px;
    height: 72px;
    left: 0rem;
    top: -2.4rem;
    }
  }
  .section-4{
    position: relative;
    .bolinhas2{
      right: -20rem;
      bottom: -10rem;
    }
    .blob4{
      left: -25rem;
      top: -5rem;
    }
    .column::before{
      display: none;
    }
    .column{
      align-items: center;
      .title,p{
        text-align: center;
      }
    }
  }
    .section-4::before{
      content: "";
      position: absolute;
      height: 121px;
      width: 178px;
      background-color: #FF7778;
      opacity: 0.23;
      top: -3.5rem;
      right: 0rem;
    }
   .section-5{
     .bolinhas4{
       top: 24rem;
       left: -17rem;
     }
     .documentos{
       padding: 40px;
       .docs{
        left: 22%;
      }
      .subtitle{
        font-size: 1.55rem;
        margin-bottom: 1.6rem;
      }
     }
    .documentos::after{
      width: 155px;
      height: 54px;
      left: 4rem;
      top: -3rem;
    }
    .mini-title{
      font-size: 1.125rem;
    }
    .mini-title::before{
      width: 65px;
      height: 9px;
      bottom: -0.8rem;
    }
   }
   .section-6{
     .bolinhas3{
       bottom:0;
       right: 2rem;
       top: 52%;
     }
   }
  }
}






//pros keyframes
@media(max-width:1720px){
  @keyframes Queda3{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -30rem;
    }
  }
  @keyframes Queda2{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -16rem;
    }
  }
}
@media(max-width:1260px){
  @keyframes Queda3{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -34rem;
    }
  }
  @keyframes Queda2{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -18rem;
    }
  }
}
@media(max-width:1090px){
  @keyframes Queda3{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -38rem;
    }
  }
  @keyframes Queda2{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -24rem;
    }
  }
}
@media(max-width:900px){
  @keyframes Queda{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -17rem;
    }
  }
  @keyframes Queda3{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -23rem;
    }
  }
  @keyframes Queda2{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -12rem;
    }
  }
}
@media(max-width:768px){
  @keyframes Queda3{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -28rem;
    }
  }
}
@media(max-width:550px){
  @keyframes Queda3{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -32rem;
    }
  }
}
@media(max-width:470px){
  @keyframes Queda2{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -15rem;
    }
  }
  @keyframes Queda3{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -35rem;
    }
  }
}

@media(max-width:380px){
  @keyframes Queda2{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -17rem;
    }
  }
  @keyframes Queda3{
    0%{
      bottom: -5rem;
    }
    100%{
      bottom: -42rem;
    }
  }
}