#title {
  .container-title {
    width: 100%;
    max-width: 500px;
    height: 74px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      height: 60px;
    }
  }

  // titulo das paginas
  .title-page {
    font-size: 2.1rem;
    color: #2c627a;
    font-family: 'Teko';
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 1.57rem;
    }
  }

  .rotas {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .link-title {
    text-decoration: none;
    letter-spacing: 0.07px;
    color: #999999;
    text-align: left;
    font-size: 0.9rem;
    text-transform: uppercase;
    @media (max-width: 350px) {
      font-size: 0.7rem;
    }
  }
  .link-title:hover {
    text-decoration: underline;
    color: #999999;
  }

  .background-red-vertical {
    position: absolute;
    left: 0;
    top: 0;
    height: 279px;
    width: 33px;
    background-color: #ff7778;
    opacity: 0.1;
  }
  .background-red-horizontal {
    position: absolute;
    left: 0;
    bottom: 0.8rem;
    height: 33px;
    width: 279px;
    background-color: #ff7778;
    opacity: 0.1;
    @media (max-width: 768px) {
      bottom: 0.5rem;
      width: 240px;
    }
  }
}
