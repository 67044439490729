#editais{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title{
    width: 100%;
    max-width: 992px;
    letter-spacing: 0px;
    color: #02354D;
    text-align: left;
    font-size: 4.2rem;
    font-family: 'teko';
    position: relative;
    font-weight: normal;
    line-height: 1.2;
    @media(max-width:550px){
      font-size: 2.125rem;
      text-align: center;
    }
  }
  .mini-title{
    font-size: 1.55rem;
  }
  p{
    font-size: 1rem;
    font-weight: 300;
  }

  .section-1{
    width: 60%;
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  .section-2{
    width: 70%;
    display: flex;
    align-items: flex-start;
    margin: 136px 0 98px 0;
    a{
      display: inline-block;
      width: 100px;
      height: 40px;
      text-align: center;
      letter-spacing: 0.08px;
      color: white;
      font-weight: bold;
      background: #3E92B7;
      line-height: 40px;
      margin-left: 30px;
      transition: background linear .5s;
      box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
    }
    a:hover{
      background: #FF7778;
    }

    .arquivos{
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 3rem;
      background-color: #FFFFFF;
      box-shadow: 16px 16px 16px #00000029;
      padding: 29px 20px 134px 100px;
      position: relative;
    }
    .arquivos::after{
      content: "";
      width: 350px;
      height: 350px;
      background-color: #FF7778;
      opacity: 0.23;
      position: absolute;
      top: -5rem;
      left: -8rem;
      z-index: -1;
    }
    .retangle::after{
      content: "";
      position: absolute;
      width: 14px;
      height: 104px;
      background-color:  #3E92B7;
      left: -2rem;
      top: 0;
    }
    .margin{
      margin-top: 35px;
    }
  }
}

@media(max-width:550px){
  #editais{
    .section-1{
      width: 90%;
    }
    .section-2{
      margin-top: 65px;
      width: 100%;
      .arquivos{
        margin: 0 10px;
        padding: 20px;
        p{
          text-align: center;
        }
        .retangle{
          margin-top: 20px;
        }
        .retangle::after{
          width: 128px;
          height: 8px;
          left: 35%;
          top: -1.2rem;
        }
        .margin{
          margin-top: 30px;
          padding-top: 50px;
          position: relative;
          border-top: 1px solid #DADADA;
        }
      }
      .arquivos::after{
        display: none;
      }
    }
  }
}
@media(max-width:440px){
  #editais .section-2 .arquivos .retangle::after{
    left: 30%;
  }
}