#inss{
  width: 70%;
  margin: 0 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media(max-width: 1200px){
      width: 90%;
      margin: 0 5%
    }
}

.inss-text{
  text-align: left;
  width: 70%;
  font-family: "Roboto";
  color: #777;
  margin-bottom: 40px;
    @media(max-width: 1200px){
      width: 90%;
      margin: 0 5%
    }
}

.form-inss{
  display: grid;
  grid-template-columns: 2fr 2fr;
  @media(max-width: 1200px){
    width: 90%;
    display: flex;
    flex-direction: column;
  }
}

.label{
  letter-spacing: 0px;
  margin-bottom: 20px;
  margin-top:20px;
  color: #999999;
  font-size: 1.55rem;
  font-family: "teko";
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  @media(max-width: 1200px){
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
}

.link-inss{
  font-family: "Roboto";
  color: #007bff;
}

.link-inss:hover{
  color:#3E92B7;
}
#input{
  width: 100%;
  align-items: left;
  margin-left: -50px;
  .input-inss{
    width: 80%;
    background-color: #FFFFFF;
    border: 2px solid #DADADA;
    height: 37px;
    padding-left: 12px;
    letter-spacing: 0.08px;
    color: #3E92B7;
    font-weight: bold;
    font-size: 1rem;
    @media(max-width: 1200px){
      width: 60%;
      margin-left: 100px;

    }
  }
  .css-2b097c-container{
    background-color: #FFFFFF;
    height: 40px;
    letter-spacing: 0.08px;
    color: #3E92B7;
    font-weight: bold;
    font-size: 1rem;
    width: 80%;
    @media(max-width: 1200px){
      width: 60%;
      margin-left: 100px;
    }
  }
}

.input-inss-anexo{
  @media(max-width: 1200px){
    width: 55%;
  }
}


.button-inss{
  margin-top: 40px;
  margin-bottom: 100px;
  background-color: #00C781;
  width: 245px;
  min-width: 245px;
  height: 90px;
  border: 0;
  transition: filter 0.3s ease-in-out;
  color: #FFFFFF;
  font-size: 1.8rem;
  font-family: 'teko';
  &:hover{
    filter: brightness(0.8);
  }
}


.title-inss-digital{
  font-size: 2.1rem;
  color: #2c627a;
  font-family: "Teko";
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  @media(max-width: 1200px){
    text-align: center;
    width: 90%;
  }
}