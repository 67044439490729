.controlMobile {
    .carousel li.slide div img {
        width: auto;
    }

    .effect-image-1{
	transition: display 0.3s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;

.portal{
	display:none;
	width: 100%;
	height: 100%;
	background: transparent 0% 0% no-repeat padding-box;
	border-radius: 5px;
	opacity: 1;
}

.portal2{
	width: 100%;
	height: 100%;
	background: transparent 0% 0% no-repeat padding-box;
	border-radius: 5px;
	opacity: 1;
}

	&:hover{
		.portal2{
			display:none !important;
		}
		.portal{
			display:block !important;
		}
	}
	&:hover::before{
		content:"Portal da Advocacia";
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font: normal normal normal 34px/34px Teko;
		letter-spacing: 0px;
		color: #FFFFFF;
	}

}

.effect-image-portal{
	transition: display 0.3s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;

.portal{
	display:none;
	width: 100%;
	height: 100%;
	background: transparent 0% 0% no-repeat padding-box;
	border-radius: 5px;
	opacity: 1;
}

.portal2{
	width: 100%;
	height: 100%;
	background: transparent 0% 0% no-repeat padding-box;
	border-radius: 5px;
	opacity: 1;
}

	&:hover{
		.portal2{
			display:none !important;
		}
		.portal{
			display:block !important;
		}
	}
	&:hover::before{
		content:"Portal da Advocacia";
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font: normal normal normal 34px/34px Teko;
		letter-spacing: 0px;
		color: #FFFFFF;
	}

}

.texto{
	font-size: 20px;
}

}

.vejaMais{
	color: white;
}