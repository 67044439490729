.details-style {
  background-color: #2c627a;
  text-align: center;
  height: 0px;
  transition: 0.5s ease;
}

.details-style-after {
  height: 80px;
  background-color: #2c627a;
  transition: 0.5s ease;
}
.select-option-style {
  background-color: #2c627a;
  text-align: center;
  height: 0px;
  color: #fff;
  font-size: 2.7em;
  width: 300px;
  font-family: 'Teko';
  font-weight: 400;
  transition: 0.5s ease;

  .option-title {
    font-family: 'Teko';
    font-weight: 400;
    display: none;
    font-size: 1.2em;
  }
}

.select-option-after {
  background-color: #2c627a;
  text-align: center;
  font-family: 'Teko';
  font-weight: 400;
  width: 300px;
  height: 300px;
  color: #fff;
  font-size: 2.7em;
  transition: 0.5s ease;

  .option-title {
    font-family: 'Teko';
    font-weight: 400;
    display: block;
    font-size: 1.2em;
  }
}

.text-style {
  font-family: 'Teko';
  font-weight: 400;
}

.card-mobile-style {
  border: none;
  background-color: whitesmoke;
}

.card-mobile-style-color-change {
  border: none;
  background-color: #2c627a;

  .card-body-mobile-style {
    color: #fff;
  }
}

.card-body-mobile-style {
  color: #2c627a;
  font-weight: bolder;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.6em;
  font-family: 'Teko';
  font-weight: 400;
}

.details-mobile {
  border: none;
  background-color: #2c627a;
}

.details-body-mobile-style {
  color: #fff;
  font-weight: bolder;
  text-transform: uppercase;
  text-align: start;
  font-size: 1.5em;
  font-family: 'Teko';
  font-weight: 400;
}

.arrow-right {
  position: relative;
  margin: auto;
  bottom: 50%;
  left: 50%;
  width: 26%;
}

.arrow-down {
  position: relative;
  margin: auto;
  left: 70%;
  bottom: 30px;
  width: 26%;
}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
