#tribunal-etica {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    letter-spacing: 0px;
    color: #444444;
    font-size: 1.6rem;
    font-family: 'Teko';
    font-weight: 400;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 1.3rem;
    }
  }
  p {
    letter-spacing: 0.08px;
    color: #333333;
    font-size: 1rem;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
  h3 {
    letter-spacing: 0px;
    color: #444444;
    font-size: 2.2rem;
    font-family: 'Teko';
    font-weight: 400;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 1.57rem;
    }
  }
  h5 {
    letter-spacing: 0px;
    color: #ffffff;
    font-family: 'Teko';
    font-weight: 400;
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  .section-1 {
    width: 60%;
    display: flex;
    align-items: flex-start;
    @media (max-width: 560px) {
      width: 100%;
      justify-content: flex-start;
      padding-left: 20px;
    }
  }

  .section-2 {
    width: 60%;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: -20rem;
    margin-top: 35px;
    @media (max-width: 768px) {
      margin-top: 109px;
    }
    @media (max-width: 500px) {
      width: 100%;
      .container {
        width: 90% !important;
      }
    }

    .container {
      width: 100%;
      max-width: 831px;
      min-width: 318px;
      display: flex;
      flex-direction: column;
      padding: 0 45px;
      position: relative;
      background-color: white;
      box-shadow: 5px 5px 10px #0000000f;
      @media (max-width: 768px) {
        padding: 0 9px;
        .grid {
          margin-top: 17px !important;
          grid-template-columns: 1fr !important;
          grid-gap: 0px;
          p {
            margin-bottom: 10px;
          }
        }
        .square-left {
          img {
            width: 30%;
          }
        }
      }
    }
    .grid {
      display: grid;
      grid-template-columns: 0.5fr 1fr;
      max-width: 550px;
      margin-top: 50px;
      align-items: center;
      grid-gap: 10px;
    }
    .membros {
      display: grid;
      grid-template-columns: 1fr;
      max-width: 350px;
      margin-top: 20px;
      margin-bottom: 100px;
      grid-gap: 10px;
      @media (max-width: 768px) {
        margin-bottom: 250px;
      }
    }
    .container-trienio {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -6rem;
      width: 288px;
      height: 288px;
      text-align: center;
      background-color: #ededed;
      bottom: 16rem;
      @media (max-width: 950px) {
        width: 200px;
        height: 200px;
      }
      @media (max-width: 768px) {
        top: -5rem;
        right: 0;
        left: 0;
        height: 50px;
        width: 100%;
      }
    }
    .square-left {
      position: absolute;
      left: -1.3rem;
      bottom: -2rem;
      width: 100%;
      max-width: 384px;
    }
    .square-right {
      position: absolute;
      right: -1.5rem;
      top: -5.5rem;
      width: 100%;
      max-width: 124px;
      img {
        width: 100%;
      }
    }
  }

  .section-3 {
    width: 100%;
    background-color: #02354d;
    height: 400px;
  }

  .section-4 {
    width: 60%;
    padding-top: 26px;
    padding-bottom: 38px;
    @media (max-width: 768px) {
      width: 100%;
      h3 {
        padding-left: 20px;
      }
    }

    table {
      width: 100%;
      margin-top: 17px;
      td {
        padding-top: 15px;
      }
      th {
        letter-spacing: 0.08px;
        color: #333333;
        font-size: 1rem;
        font-weight: 400;
        min-width: 100px;
      }
      th:first-child {
        padding-left: 10px;
      }
      tbody {
        border-top: 2px solid #cccccc;
        border-bottom: 2px solid #cccccc;
        td:first-child {
          padding-left: 10px;
          letter-spacing: 0.08px;
          color: #333333;
          font-size: 1rem;
          font-weight: bold;
          min-width: 150px;
        }
        td {
          letter-spacing: 0.08px;
          color: #333333;
          font-size: 1rem;
          min-width: 100px;
        }
      }
    }
    .Meses {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .collapse-card {
      width: 100%;
      height: 62px;
      background-color: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 1px #00000029;
      z-index: 10;
    }
    .collapse-container-card {
      transition: all 0.4s ease;
      height: 0;
      background-color: #ededed;
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      opacity: 0;
      justify-items: center;
      box-shadow: 0px 5px 25px #00000029;
    }
    .show-collapse {
      transition: all 0.4s ease;
      height: 120px;
      opacity: 1;
      padding: 10px 0;
    }
  }
  .section-5 {
    width: 100%;
    background-color: #aa4f50;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 0;
    .obs {
      width: 60%;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        width: 95%;
      }
      li {
        list-style: none;
        letter-spacing: 0.08px;
        color: #ffffff;
        font-weight: 300;
        font-size: 1rem;
      }
    }
  }

  .section-6 {
    width: 100%;
    height: 550px;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      height: 100%;
      margin-bottom: 120px;
      padding-top: 27px;
    }

    .cards {
      width: 60%;
      display: flex;
      flex-direction: row;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      @media (max-width: 500px) {
        width: 100%;
      }
    }
    .card-container {
      display: flex;
      flex-direction: column;
      margin-right: 1px;
      width: 100%;
      @media (max-width: 768px) {
        min-width: 320px;
        margin-right: 0;
      }
    }
    .margin {
      @media (max-width: 768px) {
        margin-top: 13px;
      }
    }
    .card {
      transition: all 0.5s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 120px;
      width: 100%;
      max-width: 430px;
      background-color: rgba(62, 146, 183, 0.5);
      position: relative;
      border-radius: 0 !important;
      border: none !important;
      cursor: pointer;
      z-index: 10;
      @media (max-width: 768px) {
        height: 120px;
        max-width: 100%;
        background-color: rgba(62, 146, 183, 1);
      }
      h5 {
        font-size: clamp(1.2rem, 1.302vw, 1.8rem) !important;
      }
    }
    .scale {
      transition: all 0.5s ease;
      transform: scaleY(1.1);
      background-color: rgba(62, 146, 183, 1);
    }
    .container-card {
      transition: all 0.5s ease;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      width: 100%;
      height: 0;
      box-shadow: 0px 5px 25px #00000029;
      text-align: center;
      h4 {
        font-size: clamp(1.1rem, 1.302vw, 1.8rem) !important;
      }
      p {
        font-size: clamp(0.8rem, 0.83vw, 1.2rem) !important;
      }
    }
    .show {
      transition: all 0.5s ease;
      opacity: 1;
      height: 36vh;
      padding-top: 25px;
      padding-bottom: 45px;
      @media (min-height:850px) and  (max-height:860px) and (max-width:800px){
        height: 350px;
      }
    }
  }
}
