.panel:hover {
  background-color: #13bdff;
  outline: 5px solid #13bdff;
}

/* just styles for demo */
.panel {
  padding: 10px;
  background-color: lime;
}

.expand_hover {
  height: 200px;
  width: 400px;
  background-color: blue;
  transition: all 0.2s ease-in-out;
}

.expand {
  width: 150px;
  height: 200px;
  background-color: red;
}

.expand_container {
  display: block;
  width: 100%;
  margin: auto;
  // background-color: #eee;
}

.item {
  height: 100px;
  display: block;
  background-color: #02354d;
  transition: all, 0.5s;
  width: 200px;

  h1 {
    color: grey;
    word-break: break-all;
  }
}

.item:hover {
  width: 100%;
  box-shadow: 0px 0px 50px rgb(102, 102, 102);

  h1 {
    color: white;
  }

  .position_item {
    // right: 30%;
    right: 3%;
  }
}

.grow-left {
  direction: rtl;
}
.grow-left > * {
  direction: ltr;
}

.exam-text-color {
  color: #999999;
}

.grow {
  height: 160px;
  width: 160px;
  background-color: #02354d;
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
  color: #02354d;

  .exam-text-color {
    color: white;
  }
}

.position_item {
  width: 100%;
  position: absolute;
  top: 25%;
  right: 0%;
  transition: all 0.2s ease-in-out;
}
