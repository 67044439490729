.Acessos{
	margin-left: -110px;
}

.acessos-mobile{
	text-align: left;
	margin: 0;
}

.inss-portal-mobile{
	height: 100%;
	width: 55%;
	padding: 5px;
}
.organization{
	display: flex;
	flex-direction: row;
}