.carousel li.slide div img {
    width: auto;
    &:first-child {
        display: none;
    }
}

p.cardTitleMobile {
    position: absolute;
    top: 7%;
    left: 35%;
    width: 40%;
    max-width: 200px;
    background-color: #2c627a;
    color: #fff;
}

.card-img-top {
    filter: blur(3px);
    transition: all 0.2s ease-in-out;
    width: 100%;
    object-fit: contain;
}

.card-img-overlay {
    p {
        text-align: right;
        height: 10%;
        transition: all 0.3s ease-in-out;

        /*  display: flex;
        justify-content: flex-end;
        align-items: center; */
        span {
            font-family: 'Teko';
            font-size: 1.8rem;
            font-weight: 300;
            transform: translateX(0px);
            transition: all 0.3s ease-in-out;
        }
    }
    p.textoComplementar {
        font-family: 'Teko';
        font-weight: 500;
        opacity: 0;
        background-color: transparent;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        height: auto;
        text-shadow: 1.5px 1.5px black;
        margin-left: auto;
        margin-right: auto;
        margin-top: 6rem;
        line-height: 4rem;
        width: 80%;
        font-size: 2.3rem;
    }
}
.card-above {
    position: absolute;
    top: 89%;
    left: 30%;
    transition: all 0.1s ease-in-out;
    a {
        font-family: 'Teko';
        font-size: 1.5rem;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    a:hover {
        color: white;
    }
}

.noOverflow {
    overflow: hidden;
    transform: scaleX(1);
    height: 500px;
    transition: all 0.2s ease-in-out;

    &.scaleToLeft {
        transform-origin: right;
        transition: all 0.2s ease-in-out;
        .card-img-overlay p.textoComplementar {
            margin-top: 6rem;
            width: 90%;
        }
    }
    &.scaleToRight {
        transform-origin: left;
        transition: all 0.2s ease-in-out;
        p span {
            color: black;
            border-bottom: 3px solid #000;
            padding-bottom: 5px;
            transition: all 0.2s ease-in-out;
        }
        .card-img-overlay p.textoComplementar {
            margin-top: 7rem;
            width: 50%;
        }
    }
}

.noOverflow:hover {
    cursor: pointer;
    height: 600px;
    //transform: scaleX(1.1);
    z-index: 10;
    
    /* .card-img-top {
        transform: scaleX(1.2);
    } */
    .card-img-top {
        width: 120%;
        object-fit: contain;
    }
    .card-img-top {
        filter: blur(0);
    }

    .card-above {
        background-color: white;
        a {
            color: #2c627a;
        }
    }

    .card-img-overlay {
        p {
            background-color: #2c627a;
            color: white;
            height: 10%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            opacity: 0.9;
            transition: all 0.3s ease-in-out;
            span {
                font-family: 'Teko';
                transform: translateX(-125px);
                border: none;
                transition: all 0.3s ease-in-out;
            }
        }
        p.textoComplementar {
            opacity: 1;
            background-color: transparent;
            color: white;

            text-align: center;

            display: flex;
            justify-content: center;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            margin-top: 6rem;
            line-height: 4rem;
            width: 80%;
            font-size: 2.3rem;
        }
    }

    &.scaleToLeft {
        transform-origin: right;
        transition: all 0.3s ease-in-out;
        .card-img-overlay {
            p {
                transition: all 0.3s ease-in-out;
                span {
                    transform: translateX(-90px);
                    transition: all 0.3s ease-in-out;
                    // border: none;
                }
            }
            p.textoComplementar {
                margin-top: 6rem;
                width: 90%;
            }
        }
    }
    &.scaleToRight {
        transform-origin: left;
        transition: all 0.3s ease-in-out;
        .card-img-overlay {
            p {
                transition: all 0.3s ease-in-out;
                span {
                    transform: translateX(-150px);
                    color: white;
                    transition: all 0.3s ease-in-out;
                    // border: none;
                }
            }
            p.textoComplementar {
                margin-top: 7rem;
                width: 50%;
            }
        }
    }
}




.noOverflowMobile:hover {
    cursor: pointer;
    //transform: scaleX(1.1);
    z-index: 10;
    /* .card-img-top {
        transform: scaleX(1.2);
    } */
    .card-img-top {
        width: 120%;
        object-fit: contain;
    }
    .card-img-top {
        filter: blur(0);
    }

    .card-above {
        background-color: white;
        a {
            color: #2c627a;
        }
    }

    .card-img-overlay {
        p {
            
            color: white;
            height: 7%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            opacity: 0.9;
            transition: all 0.3s ease-in-out;
            span {
                font-family: 'Teko';
                transform: translateX(-125px);
                border: none;
                transition: all 0.3s ease-in-out;
            }
        }
        p.textoComplementar {
            opacity: 1;
            background-color: transparent;
            color: white;

            text-align: center;

            display: flex;
            justify-content: center;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            margin-top: 6rem;
            line-height: 4rem;
            width: 80%;
            font-size: 2.3rem;
        }
    }

    &.scaleToLeft {
        transform-origin: right;
        transition: all 0.3s ease-in-out;
        .card-img-overlay {
            p {
                transition: all 0.3s ease-in-out;
                span {
                    transform: translateX(-90px);
                    transition: all 0.3s ease-in-out;
                    // border: none;
                }
            }
            p.textoComplementar {
                margin-top: 6rem;
                width: 90%;
            }
        }
    }
    &.scaleToRight {
        transform-origin: left;
        transition: all 0.3s ease-in-out;
        .card-img-overlay {
            p {
                transition: all 0.3s ease-in-out;
                span {
                    transform: translateX(-150px);
                    color: white;
                    transition: all 0.3s ease-in-out;
                    // border: none;
                }
            }
            p.textoComplementar {
                margin-top: 7rem;
                width: 50%;
            }
        }
    }
}

