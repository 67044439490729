#caa-container {
    max-height: 700px;
    overflow: hidden;

    #diretoria-background {
        background-color: #2C627A;
        margin-top: 30px;
        height: 280px;
        width: 100%;
        z-index: -1;
    }

    #diretores {
        width: 60%;
        padding: 2%;
        background-color: #02354D;
        margin-left: 20%;
        position: relative;
        top: -300px;
        color: white;

        #data-title {
            text-align: center;
            margin-bottom: 10px;
            font-family: Teko;
            font-size: 24px;
        }

        #data-info{
            display: grid;
            grid-gap: 10%;
            grid-template-columns: 45% 45%;
            .column{
                .cargo{
                    margin-top: 20px;
                    font-family: Teko;
                    font-size: 20px;
                }
                .ocupante{
                    font-size: 13px;
                    font-weight:normal;
                }
            }
        }

        @media (max-width: 1300px) {
            margin-left: 15%;
            width: 70%;
        }
    }

    @media (max-width: 767.9px) {
        #diretoria-background{
            background: transparent;
        }
        
        #diretores {
            margin-left: 0;
            padding: 50px 2%;
            width: 100%;

            #data-info{
                display: flex;
                flex-direction: column;
                text-align: center;
            }
        }
    }
}