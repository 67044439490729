#select {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .triangulo {
    transition: all 0.5s ease;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 12px solid #8b8b8b;
    position: absolute;
    right: 2rem;
    z-index: 12;
  }
  .triangulo:hover {
    transform: scale(1.4);
  }

  .rotate-select {
    transition: all 0.5s ease;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #8b8b8b;
    position: absolute;
    right: 2rem;
    transform: rotate(-180deg);
    z-index: 12;
  }

  .container-select {
    transition: all 0.5s ease;
    width: 100%;
    position: absolute;
    top: 57px;
    background-color: white;
    height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    pointer-events: none;
  }
  .container-select-show {
    transition: all 0.5s ease;
    width: 100%;
    position: absolute;
    top: 57px;
    background-color: white;
    height: 660px;
    padding-top: 39px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0px 5px 15px #0000001a;
    overflow-y: scroll;
  }
  li {
    transition: all 0.5s ease;
    list-style: none;
    height: 51px;
    width: 100%;
    letter-spacing: 0.1px;
    color: #333333;
    font-size: 1.3rem;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      font-size: 1.1rem;
    }
    @media (max-width: 550px) {
      height: 30px;
      margin: 8px 0;
      font-size: 0.8rem;
      cursor: pointer;
      text-transform: uppercase;
    }
  }
  li:hover {
    transition: all 0.5s ease;
    background-color: white;
    box-shadow: 0px 5px 15px #0000001a;
    letter-spacing: 0.1px;
    font-weight: 500;
    height: 51px;
    @media (max-width: 550px) {
      height: 30px;
      margin: 8px 0;
      font-size: 0.9rem;
      background-color: transparent;
      box-shadow: none;
      cursor: pointer;
    }
  }
  ul {
    list-style: none;
    width: 100%;
  }
  .options {
    transition: all 0.5s ease;
    opacity: 0;
    pointer-events: none;
    width: 100%;
  }
  .options-show {
    transition: all 0.5s ease;
    opacity: 1;
    width: 100%;
  }

  .mobile-container-select {
    transition: all 0.5s ease;
    width: 100%;
    position: absolute;
    bottom: 1rem;
    background-color: #efeff4f0;
    height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    pointer-events: none;
    z-index: 10;
  }
  .mobile-container-select-show {
    transition: all 0.5s ease;
    width: 100%;
    position: absolute;
    bottom: 1rem;
    background-color: #efeff4f0;
    height: 300px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow-y: scroll;
    z-index: 10;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  ::-webkit-scrollbar-thumb {
    background: #392023;
    border-radius: 30px;
  }
}

#background {
  transition: all 0.3s ease-in-out;
  opacity: 0;
  height: 100vh;
  width: 100%;
  pointer-events: none;
  position: absolute;
}
#background-black {
  transition: all 0.3s ease-in-out;
  opacity: 0.8;
  background-color: black;
  z-index: 0;
  height: 200vh;
  width: 100%;
  pointer-events: none;
  position: absolute;
}
