.card-component{
  height: 10rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  margin: 0.2rem;
}

.card-style-web {
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Teko';
  font-weight: 400;
  color: #2c627a;
}

.card-component:hover .card-style-web{
  color: #fff;
}

.pointer {
  cursor: pointer;
  @media (max-width: 550px) {
    width: 100%;
  }
}

.color-one {
  background-color: white;
  border: none;
  transition: all 0.5s ease-in-out;
  @media (max-width: 550px) {
    width: 100%;
  }
}
.color-one:hover {
  background-color: #1189c3;
  box-shadow: 2px 2px 20px #999;
  transform: scale(1.2);
  :active{
    background-color: #2c627a;
    transform: scale(1.2);
  }
}

.color-one-mobile {
  background-color: whitesmoke;
  border: none;

  :hover {
    background-color: whitesmoke;
    .card-style-web {
      color: #2c627a;
    }
  }
}

.color-two {
  background-color: #2c627a;
  border: none;

  .card-style-web {
    color: white;
  }
}

.body-style-web {
  text-align: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    width: 100%;
  }
}

.body-style-mobile {
  text-align: center;
  border: none;
  padding-top: 50px;
}

.body-style-mobile {
  text-align: center;
  border: none;
}
