#diario-oab{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    #section-1{
        width: 70%;
    }

    #section-2::after{
        content: "";
        width: 350px;
        height: 350px;
        background-color: #FF7778;
        opacity: 0.23;
        position: absolute;
        top: -5rem;
        left: -8rem;
        z-index: -1;
    }
    #section-2{
        margin-top: 70px;
        margin-bottom: 20px;
        padding: 50px;
        width: 70%;
        margin-left: 5%;
        background-color: white;
        box-shadow: 16px 16px 16px #00000029;
        position: relative;
        .details{
            position: absolute;
            right: -7.5rem;
            top: -8.5rem;
            z-index: -1;
          }

        #release-title{
            display: flex;
            align-items: center;
            #bar{
                width: 15px;
                height: 70px;
                background-color: #3E92B7;
                margin-right: 20px;
            }

            font-family: 'Teko';
            font-size: 50px;
        }

        #release-text{
            margin-left: 25px;
            word-wrap: wrap;
            opacity: 0.9;
            font-size: 15px;
        }
    }
    #editions-title{
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        width: 70%;
        margin-top: 50px;
        #title{
            font-family: 'Teko';
            font-size: 34px;
            width: 35%;
            line-height: 50px;
        }
        #busca{
            width: 65%;
        }
    }

    #section-3{
        margin: 30px 0 50px 0;
        width: 70%;
        display: flex;
        flex-direction: row;
        
        min-height: 400px;

        #left {
            width: 50%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            padding-right: 50px;
            position: relative;

            #container-selects{
                justify-content: space-around;
                width: 90%;
                color: #fff;
                margin: 0 auto;
                padding-top: 2rem;
                padding-bottom: 2rem;
                background-color: #2C627A;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
        }
        #right {
            width: 50%;

            .selected-item{
                width: 100%;
                background-color: #2c627a;
                text-align: center;
                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 50px;
                #selected-item-title{
                    font-family: 'Teko';
                    font-size: 30px;
                    margin-bottom: 30px;
                }
                .selected-item-link{
                    text-align: start;
                    width: 100%;
                    margin: 10px 0;
                    a{
                        margin-left: 15px;
                        font-size: 20px;
                        font-family: 'Teko';
                        text-decoration: none;
                        color: white;
                        letter-spacing: 0.5px;
                        &:hover{
                            opacity: 0.5;
                        }
                    }
                }
            }

        }
    }

    @media (max-width: 1100px) {
        #section-3{
            width: 90%;
        }
    }

    @media (max-width: 767.9px) {

        #section-1{
            width: 90%;
        }
        #section-2{
            display: none;
        }

        #editions-title{
            #title{
                width: 100%;
            }
        }

        #section-3{
            width: 100%;
            flex-direction: column;
            align-items: center;
            #left{
                width: 80%;
                margin: 0;
                padding: 0;
                flex-direction: column;
            }

            #right{
                width: 80%;
                margin: 20px 10%;
            }
        }
    }
}
