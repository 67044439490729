.modalPopup {
  height: 10px;
  width: 20vw;
  position: fixed;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  margin-bottom: 350px;
  margin-left: 36vw;
  border-radius: 10px;
  @media(max-width: 768px){
    display: none;
  }
}

.img_banner_banese{
  width: 400px;
  height: 400px;
}

.button-fechar {
  height: 30px;
}
.text-fechar {
  font-family: "Roboto";
}
