#tv-oab{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    #section-1 {
        width: 70%;
        display: flex;
        align-items: flex-start;
        @media (max-width: 550px) {
          width: 100%;
          padding-left: 20px;
        }
    }

    #section-2{
        width: 80%;
        margin-left: 10%;
        display: flex;

        #bar{
            width: 15px;
            background-color: #3E92B7;
            margin-right: 20px;
            height: 70px;
        }
        #title{
            font-size: 55px;
            color: #02354D;
            font-family: 'Teko';
            max-width: 800px;
        }    
    }

    #section-3::after{
        content: "";
        width: 219px;
        height: 251px;
        background-color: #FF7778;
        opacity: 0.23;
        position: absolute;
        top: 6rem;
        left: -4.5rem;
        z-index: -1;
    }
    #section-3 {
        width: 80%;
        margin: 50px 0 0 10%;
        margin-left: 10%;
        margin-top: 50px;
        display: flex;
        position: relative;
        .details{
            position: absolute;
          }
          .bolinhas8{
            right: 8.5rem;
            top: -10.5rem;
            z-index: -1;
          }
        iframe{
            height: 50vh;
            width: 45vw;
            min-width: 100px;
            min-height: 450px;
        }

        #subscribe{
            margin-left: 20px;
            height: 50vh;
            width: 18vw;
            padding: 10px;
            background-color: #2C627A;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 100px;
            min-height: 450px;
            #subscribe-text{
                color: white;
                font-family: 'Teko';
                font-size: 2.7vw;
                text-align: center;
            }
            a{
                margin-top: 20px;
                width: 70%;
                height: 4vw;
                background-color: #00C781;
                border: none;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 70px;
                min-width: 120px;
                text-decoration: none;
                transition: 300ms;

                &:hover{
                    background-color: #00A560;
                }
                svg{
                    fill: white;
                    width: 35px;
                    height: 35px;
                    margin-right: 10px;
                    path{
                        width: 30px;
                    }
                }
                div{
                    font-family: 'Teko';
                    font-size: 1.8vw;
                    margin-top: 5px;
                }
            }
        }
    }
    #section-4{
        margin-top: -2.5rem;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        position: relative;
        z-index: -1;
        overflow: hidden;
        .details{
           margin-left: -12rem;
        }
    }

    @media (max-width: 767.9px) {
        #section-2{
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-left: 0;
            #bar{
                width: 70%;
                height: 8px;
            }

            #title{
                margin-top: 20px;
                font-size: 40px;
            }
        }
        #section-3::after{
            content: "";
            width: 82px;
            height:94px;
            background-color: #FF7778;
            opacity: 0.23;
            position: absolute;
            top: -2rem;
            left: 0rem;
            z-index: -1;
        }
        #section-3{
            width: 100%;
            flex-direction: column;
            margin-left: 0;
            padding: 0 20px;
            .bolinhas8{
                top: 8rem;
                right: 0;
                height: 176px;
            }
            iframe{
                width: 100%;
                height: 50vw;
                min-height: 0 ;
            }

            #subscribe{
                width: 100%;
                min-height: 0;
                margin-left: 0;
                margin-top: 50px;
                height: auto;
                min-height: 0;
                padding-bottom: 50px;
                #subscribe-text{
                    font-size: 30px;
                }

                a{
                    div{
                        font-size: 30px;
                    }    
                }
            }
        }
        #section-4{
            .details{
                margin-left: 0;
                height: 150px;
                width:60%;
            }
        }
    }
}