#caixa-economica{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  
  .bolinhas5{
    position: absolute;
    top: 57.5%;
    right: 0;
    pointer-events: none;
  }
  .bolinhas6{
    position: absolute;
    top: 60%;
    left: 0;
    pointer-events: none;
  }

  a{
    text-transform: uppercase;
    color: #FFFFFF;
  }
  .title{
    width: 100%;
    max-width: 992px;
    letter-spacing: 0px;
    color: #02354D;
    text-align: left;
    font-size: 4.2rem;
    font-family: 'teko';
    position: relative;
    font-weight: normal;
  }

  p{
    // max-width: 797px;
    text-align: left;
    letter-spacing: 0.09px;
    color: #FFFFFF;
    font-size: 1.125rem;
  }

  .section-1{
    width: 70%;
    display: flex;
    align-items: flex-start;
  }
  .section-2{
    margin-top: 115px;
    width: 70%;
    display: flex;
    align-items: flex-start;
    position: relative;

    .title::before{
      content:"";
      position: absolute;
      height:124px;
      width:225px;
      background-color:#FF7778 ;
      opacity: 0.23;
      top: -3rem;
      left: -4rem;
    }
    .bolinhas2{
      position: absolute;
      right: -7.5rem;
      bottom: -11rem;
      z-index: -1;
      pointer-events: none;
      @media (max-width:800px){
        display: none;
       }
    }
  }
  .section-3{
    margin: 50px 0;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #02354D;
    box-shadow: 13px 13px 15px #00000033;
    padding:29px 49px;
    position: relative;
    overflow: hidden;
    .details{
      position: absolute;
      pointer-events: none;
    }
    .blob3{
      left: -25rem;
      bottom: -10rem;
      height: 800px;
    }
    .blob4{
      top: -20rem;
      right: 0;
    }
    .bolinhas3{
      bottom: -7.5rem;
      left: 30%;
    }

    p{
      margin: 20px 0;
    }
  }
  .section-4, .section-41{
    width:70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    .subtitle{
      color: #444444;
      font-size:2.125rem;
    }
    .mini-title{
      letter-spacing: 0.13px;
      color: #FFFFFF;
      font-size:1.55rem;
    }
    .documentos::-webkit-scrollbar{
      display: none;
    }

    .documentos{
      display: flex;
      flex-direction: row;
      background-color: #3E92B7;
      max-height: 483px;
      // overflow-y: scroll;
      @media (max-width:1600px){
        max-height: 683px;
      }
      @media (max-width:1500px){
        .right-red{
          min-width: 400px !important;
          margin-bottom: -50rem !important;
        }
      }
      @media (max-width:1180px){
        max-height: 883px;
        .left{
          margin: 30px !important;
        }
      }
      @media (max-width:1040px){
       flex-direction: column;
       padding: 40px;
       .left,.right-red{
         width: 100% !important;
         margin: 0 !important;
       }
      }

      .left{
        width:45%;
        margin: 49px 77px 49px 49px;
      }
      .right-red{
        min-width: 559px;
        position: relative;
        margin: -1.5rem 0 -40rem 0;
        display: flex;
        flex-direction: column;
        background-color: #FF7778;
        padding: 49px;
        width: 42%;
        p{
          margin: 10px 0;
        }

        .container-info{
          // height: 54px;
          position: relative;
          background: #999999 0% 0% no-repeat padding-box;
          border-radius: 3px;
          cursor: pointer;
          margin-left: -3.5rem;
          margin-right: -3.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 1rem 56px;
          transition: all 0.2s ease-in-out;
          margin-bottom: 1rem;
          p{
            letter-spacing: 0.08px;
            color: #FFFFFF;
            font-size: 1rem;
            margin: 0;
            text-align: center;
          }
        }
        .infos{
          display:none;
        }
        .show{
          display: flex;
          background-color:#02354D;
          // height: 170px;
          .infos{
            border-radius: 3px;
            width:100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: 1rem 56px;
            animation: ShowCard 0.2s forwards !important;
            z-index: 10;
            display: flex;
            flex-direction: column;
          }
          p{
            margin: 3px 0;
            text-align: left;
          }
          p:first-child{
            margin-top: 0 !important;
            margin-bottom: 1rem;
            text-align: center;
          }
        }
        .anexos p{
          text-align: center;
        }
      }
    }
  }
  .section-41{
    width:70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .right-red{
      width: 100%;
      // min-width: 559px;
      position: relative;
      // margin: -1.5rem 0 -40rem 0;
      display: flex;
      flex-direction: column;
      background-color: #FF7778;
      padding: 49px;
      p{
        margin: 10px 0;
      }

      .container-info{
        // height: 54px;
        position: relative;
        background: #999999 0% 0% no-repeat padding-box;
        border-radius: 3px;
        cursor: pointer;
        margin-left: -3.5rem;
        margin-right: -3.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 56px;
        transition: all 0.2s ease-in-out;
        margin-bottom: 1rem;
        p{
          letter-spacing: 0.08px;
          color: #FFFFFF;
          font-size: 1rem;
          margin: 0;
          text-align: center;
        }
      }
      .infos{
        display:none;
      }
      .show{
        display: flex;
        background-color:#02354D;
        // height: 170px;
        .infos{
          border-radius: 3px;
          width:100%;
          position: absolute;
          top: 0;
          left: 0;
          padding: 1rem 56px;
          animation: ShowCard 0.2s forwards !important;
          z-index: 10;
          display: flex;
          flex-direction: column;
        }
        p{
          margin: 3px 0;
          text-align: left;
        }
        p:first-child{
          margin-top: 0 !important;
          margin-bottom: 1rem;
          text-align: center;
        }
      }
      .anexos p{
        text-align: center;
      }
    }
  }
  .section-5{
    width: 70%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 268px;
    @media (max-width:1040px){
      margin-bottom: 75px;
      .prazos{
        width: 100% !important;
      }
     }
    .mini-title{
      letter-spacing: 0.13px;
      color: #FFFFFF;
      font-size: 1.55rem;
    }
    p{
      margin: 40px 0;
    }
    .prazos{
      margin-top: 17px;
      width: 52%;
      background-color: #02354D;
      box-shadow: 13px 13px 15px #00000033;
      padding: 50px 50px 20px 50px;
      position: relative;
      overflow: hidden;
      .details{
        position: absolute;
        pointer-events: none;
      }
      .blob3{
        left: -20rem;
        bottom: -15rem;
      }
      .blob4{
        right: -20rem;
        top: -20rem;
      }
      .bolinhas3{
        right: 0;
        bottom: -2rem;
      }
    }
  }
}

@keyframes ShowCard {
  0%{
    display: flex !important;
    opacity: 0;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity:1;
  }
}

@media (max-width:550px){
  #caixa-economica{
    .bolinhas5,.bolinhas6{
      display: none;
    }
    .section-1,.section-2,.section-3,.section-4,.section-41,.section-5{
      width: 90%;
    }
    .section-3,.section-4 .documentos,.section-5 .prazos{
      height: 576px;
      overflow: scroll;
      .blob4{
        right: -2rem;
        top: -5rem;
        width: 250px;
        height: 350px;
      }
      .blob3{
        height: 450px;
        left: -12rem;
        bottom: 5rem;
      }
    }
    .section-3::-webkit-scrollbar,
    .section-4 .documentos::-webkit-scrollbar,
    .section-5 .prazos::-webkit-scrollbar{
      display: none;
    }
    .title{
      font-size: 2.125rem;
    }
    .section-2{
      width: 90%;
      margin-top: 76px;
      .title::before{
      width: 225px;
      height: 72px;
      left: 0rem;
      top: -2.4rem;
      }
    }
  }
}