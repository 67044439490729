#entregadecarteira {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .section-1 {
    width: 70%;
    display: flex;
    align-items: flex-start;
  }
}
