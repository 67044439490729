#pagina-interna{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    #titulo-noticia{
        width: 60%;
        margin-right: 10%;
        display: flex;
        margin-top: 70px;
        #barra{
            width: 7px;
            background-color: #3E92B7;
            margin-right: 20px;
        }

        font-family: 'Teko';
        font-size: 40px;
    }
    #links-paginainterna{
        display: flex;
        width: 70%;
        color: #999;
        height: 33px;
        align-items: center;
        @media (max-width: 450px) {
            flex-wrap: wrap;
            align-items: flex-start;
        }
        a, p{
            color: #999;
            text-decoration: none;
            margin: 0 0.5rem;
        }
        #background-link{
            position: absolute;
            height: 33px;
            width: 279px;
            background-color: #ff7778;
            opacity: 0.1;
            z-index: -1;
        }
    }
    #data-noticia{
        width: 70%;
        font-family: 'Roboto';
        letter-spacing: 4px;
        margin: 20px 0;
        color: #999;
        #share{
            @media (max-width: 600px) {
                width: 30px;
                height: 30px;
            }
        }
        #share:hover{
            background: #00B1FF;
            cursor: pointer;
        }
        #container-compartilhar{
            display: flex;
            align-items: center;
            #container-redes-sociais{
                display: flex;
                align-items: center;
                margin: 0 1rem;
                @media (max-width: 600px) {
                    margin: 0 0.3rem;
                }
                transition: all 0.5s ease 0.5s;
                animation: openRedesSociais 0.2s ease;
                a{
                    background-color:#fad4d5;
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0.3rem;
                    @media (max-width: 600px) {
                        width: 30px;
                        height: 30px;
                        img{
                            height: 60%;
                        }
                    }
                    &:hover{
                        background-color: #4da0d6;
                    }
                }
            }
        }
    }

    #imagem-noticia{
        width: 75%;
        height: auto;
        @media (max-width: 520px) {
            width: 85%;
        }
        img{
            width: 100%;
            /*height: 400px;*/
        }
    }

    #texto-noticia{
        width: 65%;
        margin: -20px 0 50px 0;
        background-color: white;
        padding: 100px 2.5%;
        font-family: 'Roboto';
        color: #777;
        box-shadow: 20px 20px 40px #ccc;
        @media (max-width: 520px) {
            width: 75%;
        }
        img{
            max-width: 100%;
            @media (max-width: 1250px) {
                width: 100%;
            }
        }
    }

    .button-retornar{
        top: 500px;
        left: 360px;
        width: 200px;
        height: 50px;
        margin-bottom: 15px;
        border: none;
        background: #00C781 0% 0% no-repeat padding-box;
        transition: 300ms;
        a{
            text-decoration: none;
            center{
              text-decoration: none;
              color: white;
              font-size: 18px;
            }
        }
        &:hover{
            background-color: #00A560;
        }
    }      
}
@keyframes openRedesSociais {
    from {
      position: absolute;
      margin-left: 2.1rem;
      width: 0;
    }
  
    to {
      position: relative;
      width: 100%;
    }
  }