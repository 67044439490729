.text-tribunal {
  @media (max-width: 1200px) {
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
}
.logoGet {
  height: 50px;
  margin-top: -25px;
}
.span-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.getinfoLogo{
    margin-bottom: 150px;
}
