#taxas-e-emolumentos {
  width: 70%;
  margin: 0 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media(max-width: 1200px){
    width: 90%;
    margin: 0 5%
  }
  .section-1{
    width: 70%;
    display: flex;
    align-items: flex-start;
  }

  #titulo{
    width: 100%;
    text-align: left;
    letter-spacing: 0px;
    color: #2C627A;
    opacity: 1;
    font-family: Teko;
    font-size: 60px;
    display: flex;
    justify-content: space-between;

    svg{
      display: none;
    }

    p{
      margin: 0;
      font-family: Teko;
      font-size: 60px;
    }

    #container-carrinho{

      .carrinho-svg{
        display: block;
        cursor: pointer;
      }
  
      #qtd-carrinho{
        cursor: pointer;
        position: absolute;
        font-size: 16px;
        background: #2c627a;
        color: white;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-content: center;
        border-radius: 50px;
        margin-top: -35px;
        margin-left: 18px;
        padding: 0.5rem;

        p{
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          margin-top: 4px;
        }
      }
    } 
    
    @media (max-width: 767.9px) {
      margin: 0;
      text-align: center;
      width: 100%;
      flex-direction: column;

      #container-carrinho{
        display: flex;
        justify-content: center;
        align-items: center;
        #qtd-carrinho{
          position: relative;
          top: 30px;
          left: -30px;
          right: 0;
        }
      }
    }
  }

  #subtexto{
    text-align: left;
    font-family: Teko;
    letter-spacing: 0px;
    font-size: 24px;
    color: #999999;
    opacity: 1;
    margin: 10px 0;

    @media (max-width: 767.9px) {
      text-align: center;
    }
  } 
}

.paginacao{
  display: none;
}

/*Cards Taxas e Emolumentos */

#section-cards{
  #search{
    margin-bottom: 20px;
  }

  width: 100%;

  #card-group-detalhe{
    max-height: 740px;
    display: flex;
    margin-bottom: 100px;
    align-items: flex-end;
    box-shadow: 8px 8px 20px #aaa;

    #card-detalhe{
      height: 710px;
      width: 50%;
      background-color: #F3F3F3;
      padding-top: 30px;
      z-index: 2;
      background-image: url(../../../assets//images/BG.svg);
      background-position: cover;
      
      #card-header{
        width: 110%;
        right: 5%;
        position: relative;
        background: #2C627A 0% 0% no-repeat padding-box;
        box-shadow: 9px 5px 15px #00000033;
        padding: 20px 0;
        opacity: 1;
        z-index: 3;

        #card-header-text{
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 20px;
          font: 55px Teko;
          letter-spacing: 0px;
          color: #FFFFFF;
          opacity: 1;
        } 
      }

      #description-detalhe{

        text-align: left;
        letter-spacing: 0px;
        max-height: 60%;
        color: #02354D;
        opacity: 1;
        margin: 20px 20px 0 20px;



        #subtext-detalhe{

          #opcoes-pagamento{
            .opcao{
              label{
                max-width: 330px;
                font-family: Teko;
                font-size: 60px;
                cursor: pointer;
                color: #02354d;
                transition: 500ms;
              }
            }
          }

          #seu-pedido{
            font: normal normal normal 40px/40px Teko;
            color: #02354D;
          }

          #itens-comprados{

            max-height: 150px;
            overflow-y: scroll;
            margin: 20px 0;
            
            &::-webkit-scrollbar {
              width: 7px;
            }

            &::-webkit-scrollbar-track {
              background-color: #ddd;
            }
            
            &::-webkit-scrollbar-thumb {
              background: #aaa; 
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #999; 
            }

            .item-comprado-texto{
              margin-top: 10px;
              display: flex;
              align-items: center;
              text-align: left;
              letter-spacing: 0px;
              color: #999999;
              opacity: 1;
              margin-right: 5px;
  
              p{
                margin: 0;
                font-family: Teko;
                font-size: 24px;
              }
  
              svg{
                min-width: 15px;
                min-height: 15px;
                margin-left: 20px;
                cursor: pointer;
                path{
                  stroke-width: 2;
                }
              }
            }  
          }


          #linha-horizontal-detalhe-faturamento{
            width: 100%;
            margin: 0 0 10px 0;
            border: 1px solid #A9A9A9;  
          }
          
          #valor{
            font-size: 20px;
            display: flex;
            justify-content: space-between;
            font-size: 40px;
            
            #total-texto{
              font-family: Teko;
              color: #02354D;

            }

            #total-valor{
              font-family: Teko;
              color: #3e92b7;           
            }
          }
        }

        #botoes-limpar{
          display: flex;
          justify-content: space-between;

          #link{
            text-align: left;
            font: normal normal normal 20px/25px;
            letter-spacing: 0.08px;
            font-weight: bold;
            border: 0;
            margin-right: 2px;
            color: #3e92b7;
            background-color: transparent;
          }
          
          #limpar-carrinho{
            background: transparent;
            color: #959595;
            text-align: right;
            font: normal normal normal 20px/25px;
            letter-spacing: 0.08px;
            margin-left: 2px;
            opacity: 1;
            border: 0;
            font-weight: bold;
          }
        }

        #compra-no-brasil{
          margin: 20px 0;
          color: #959595;
          font-weight: bold;
          font-size: 13px;
          text-align: end;
        }
      }
    }

    #card-detalhe-form{
      height: 710px;
      padding-top: 30px;
      width: 50%;
      background-color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      #v-div{
        width: 50%;
        display: flex;
        align-items: center;
        transition: 500ms;
        position: relative;
          
        .icon{
          border-radius: 50%;
          width: 15%;
          
          &.icon-true{
            
            g{
              fill: #3e92b7;
            }

            path{
              fill: white;
            }
          }
        }

        #barra-azul{
          position: relative;
          width: 70%;
          background-color: #3e92b7;
          height: 5px;
        }

        @media (max-width: 1386px) {
          width: 70%;

          #barra-azul{
            width: 80%;
          }
        }
      }

      #pagamento{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        height: 100%;

        .pagamento-texto{
          padding: 0 60px;
          display: flex;
          justify-content: center;
          flex-direction: column;
  
          h3{
            color: #3e92b7;
            font-family: Teko;
            font-weight: 400;
            font-size: 40px;
            text-align: center;
          }

          div{
            color: #959595;
            span{
              color:#848484;
              font-weight: bold;
              font-style: italic;
            }
          }

          #nota{
            margin-top: 20px;
            font-size: 14px;
            font-weight: 300;
            color: #959595;
          }
        }

        #buttons2{
          width: 100%;
          padding: 0 7%;
          display: flex;
          justify-content: flex-end;
          flex-flow: wrap;
  
          .button-form{
            margin: 10px 10px 0 10px;
            padding: 5px 50px;
            border: 2px solid #00C781;
            color: #00C781;
            font-family: Teko;
            font-size: 24px;
            background: #fff 0% 0% no-repeat padding-box;
            opacity: 1;
            transition: 500ms;
  
            &:hover{
              color: #fff;
              background-color: #00C781;
            }
  
            &:focus{
              background-color: #00A560;
              transition: 0;
            }
          }  
        }
      }

      #form{
        overflow-y: scroll;
        height: 70%;
        padding: 0 9%;
        margin: 10px 0;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        letter-spacing: 0px;
        color: #999999;
        width: 100%;

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background-color: #ddd;
        }
        
        &::-webkit-scrollbar-thumb {
          background: #aaa; 
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #999; 
        }

        .linha-form{
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          label{
            width: 48%;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            p{
              margin: 0;
              font-family: Teko;
              font-size: 24px;
            }

            #cpf-cnpj{
              margin-top: 10px;
              padding: 20px 5%;
              background-color: #e3e3e3;
              border: none;

              #tipos-pessoa{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-top: 10px;

                button{
                  color: white;
                  font-weight: bold;
                  letter-spacing: .3px;
                  padding: 5px 0;
                  border: none;
                  width: 90px;
                  transition: 500ms;
                }
              }
            }

            .entrada{
              stroke: none;
              padding: 0 10px;
              height: 35px;
              background: #fff;
              opacity: 1;
              border: 1px solid #ccc;
              font: normal normal bold 16px/24px Roboto;
              color: #3E92B7;

              &:focus{
                outline: none;
                background-color: #F4F4F4;
              }
            }

            #estados{
              width: 100%;
              height: 35px;
              border-radius: 0;
              background-image: none;
              -webkit-appearance: auto;
              appearance: auto;
              max-height: 200px;

              &:focus{
                background-color: #F4F4F4;
              }

              p{
                margin: 0;
              }

              &::-webkit-scrollbar {
                width: 7px;
              }
      
              &::-webkit-scrollbar-track {
                background-color: #ddd;
              }
              
              &::-webkit-scrollbar-thumb {
                background: #aaa; 
              }
      
              &::-webkit-scrollbar-thumb:hover {
                background: #999; 
              }


              option{
                background-color: #E3E3E3;
                font-family: Roboto;
                font-size: 15px;
                font-weight: bold;
                color: #999;
                letter-spacing: .6px;
              }
            }
          }

          &.um{
            label{
              width: 100%;
            }
          }
        }
      }

      #buttons{
        width: 100%;
        padding: 0 7%;
        display: flex;
        justify-content: flex-end;
        flex-flow: wrap;

        .button-form{
          margin: 10px 10px 0 10px;
          padding: 5px 50px;
          border: 2px solid #00C781;
          color: #00C781;
          font-family: Teko;
          font-size: 24px;
          background: #fff 0% 0% no-repeat padding-box;
          opacity: 1;
          transition: 500ms;

          &:hover{
            color: #fff;
            background-color: #00C781;
          }

          &:focus{
            background-color: #00A560;
            transition: 0;
          }
        }  
      }
    }
  }
  @media (max-width: 768.1px) {

    #titulo{
      font-size: 20px;
      flex-direction: row;
      justify-content: start;
      align-items: center;

      svg{
        display: initial;
        height: 100%;
        margin: 0 10px;
        transition: 500ms;
        cursor: pointer;

        g{

          path{
            fill: #2c627a;
          }
        }

        &:hover{
          transform: scale(1.2);
          
          g{

            path{
              fill: #0a4a58;
            }
          }
        }
      }

      p{
        font-family: Teko;
        font-size: 30px;
      }
    }

    #subtexto{
      text-align: start;
      margin: 10px;
    }

    #card-group-detalhe{
      flex-direction: column;
      margin: 0;
      height: auto;
      align-items: center;
      max-height: none;
      margin-bottom: 50px;
      box-shadow: none;
  
      #card-detalhe{
        background-color: transparent;
        background-image: none;
        width: 100%;
        height: auto;
  
        #card-header{
          width: 88%;
          margin: 0 6%;
          right: 0;

          #card-header-text{
            font-size: 45px;
            padding: 10px;
  
            @media (max-width: 500px) {
              font-size: 35px;
            }
          }
        }
        
        #description-detalhe{
  
          #subtext-detalhe{
         
            #seu-pedido{
              font-size: 35px;
              @media (max-width: 500px) {
                font-size: 30px;
              }
            }
  
            #itens-comprados{
              max-height: none;
              overflow-y: hidden;
              
              .item-comprado-texto{
                border-bottom: 1px solid #959595;
              }
            }

            #opcoes-pagamento{
              margin-bottom: 20px;

              .opcao{
                font-size: 45px;
                max-width: 300px;
                @media (max-width: 500px) {
                  font-size: 32px;
                  max-width: 180px;
                }
              }
            }
          }
  
          #linha-horizontal-detalhe-faturamento{
            display:  none;
          }
  
          #valor{
            flex-direction: column;
            
            #total-texto{
              font-size: 35px;
              margin: 0;
              
              @media (max-width: 500px) {
                font-size: 30px;
              }
            }
  
            #total-valor{
              font-size: 35px;
              
              @media (max-width: 500px) {
                font-size: 30px;
              }
            }
          }
  
          #botoes-limpar{
            display: none;
          }
  
          #compra-no-brasil{
            display: none;
          }
        }
      }
  
      #card-detalhe-form{
        background-color: transparent;
        width: 100%;
        height: auto;
  
        #form{
          overflow-y: hidden;

          .linha-form{

            label{
              width: 100%;
  
              #cpf-cnpj{
                #tipos-pessoa{
                  justify-content: space-between;
                }
              }
            }
          }
        }
  
        #buttons{
          display: flex;
          justify-content: space-around;
          margin-bottom: 20px;
  
          .button-form{
            padding: 5px 30px;
          }
        }

        #pagamento{
          width: 100%;

          .pagamento-texto{
            padding: 20px 20px 0 20px;
            text-align: left;

            h3{
              text-align: left;
            }
          }

          #buttons2{
            display: flex;
            justify-content: space-around;
            margin-bottom: 20px;
    
            .button-form{
              font-size: 20px;
              padding: 5px 20px;
            } 
          }
        }
      }
    }
  }
}

#card{
  top: 100%;
  width: 100%;
  left: 2px;
  height: 100%;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-color: transparent;
  .card{
    align-items: center;
    justify-content: space-between;
    transition-timing-function: ease-in-out;
    transition: 300ms;
    min-width: 200px;
    width: 20%;
    margin: 0 3px 20px 3px;
    &:hover{
      font-size:1.2em;
      color: white;
      text-decoration: none;
      background-color: #2c627a;
      #button{
        background: #22E9A3 0% 0% no-repeat padding-box;
      }
      #button-carrinho{
        background: #2c627a;
        border: 1px solid #fff;
        color: #fff;
      }
      #header-description{
        color: white;
      }
      #subtext{
        color: white;
      }
      #value-description{
        color: white;
      }
    }
  }
}

#card-group{
   height: 100%;
   margin-bottom: 150px;
}

#header-description{
  text-align: left;
  font: normal normal normal 30px/35px Teko;
  letter-spacing: 0px;
  color: #02354D;
  opacity: 1;
  padding: 10px;
}

#button{
border: none;
padding: 10px 40px;
margin: 10px 0;
background: var(--ok) 0% 0% no-repeat padding-box;
background: #00C781 0% 0% no-repeat padding-box;
opacity: 1;
font: normal normal normal 25px/20px Teko;
color: #fff;
}

#button-carrinho{
  background: white;
  border: 1px solid #00c781;
  width: 80%;
  margin: 0 auto 0.5rem;
  padding: 10px 1rem;
  color: #00c781;
  font: normal normal normal 25px/20px Teko;
}

#value-description{
  text-align: center;
  font: normal normal normal 25px/30px Teko;
  letter-spacing: 0px;
  color: #02354D;
  opacity: 1;
}

#subtext{
  font: normal normal normal 25px/25px Teko;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
  padding: 5px;
}
#linha-horizontal-card {
  width: 90%;
  margin: 0 auto 10px;
  border: 1px solid #A9A9A9;
  opacity: .2;
}

@media(max-width: 767.9px){
  #taxas-e-emolumentos{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    #section-1{
      margin-left: 10%;
      width: 80%;
      max-width: 90%;
      display: flex;
      align-items: flex-start;
    }
  }

  #card{
    display: flex;
    padding: 0 1rem;
    margin-left: 0;
    left: 0;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-behavior: smooth;
    justify-content: space-between;
    border-color: transparent;
    &::-webkit-scrollbar{
      display: none;
    }
    @media(max-width:550px){
      justify-content: flex-start;
    }
  }
  
  #linha-horizontal-card {
    width: 60%;
    border: 1px solid #A9A9A9;
    opacity: .2;
    align-items: center;
  }

  #header-description, #subtext{
    text-align: center;
  }
  
  #value-description{
    padding: 10px;
    text-align: center;
  }

  .paginacao{
    width: 80%;
        margin: 2rem auto;
        height: 90px;
        //border: dashed 1px darkturquoise;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .Esquerda {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-right: 1rem;
            font-size: 2rem;
            svg {
                border: 1px solid #ff7778;
                &:hover {
                    background-color: #02283a;
                    color: white;
                }
            }
        }
        .Direita {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-left: 1rem;
            font-size: 2rem;
            svg {
                border: 1px solid #ff7778;
                &:hover {
                    background-color: #02283a;
                    color: white;
                }
            }
        }
  }

  /*Estilização do arquivo js Card Detalhe Faturamento*/

  #pesquisa{
    margin-left: 0;
    width: 100%;
  }
}