.container-selects{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.select__categoria{
    font-size: 1.2rem;
    font-family: "Teko";
    text-align: center;
    margin: 1.5rem 0rem 0rem;
    font-weight: 500;
}

.baixar-arquivos{
	width: 80%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin: 0 auto;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid #01683880;
	border-radius: 0 0 5px 5px;
    border-top: 0;
}

.baixar-arquivos > a{
	text-align: right;
	text-decoration: underline;
	font: normal normal normal 19px/41px "Roboto";
	letter-spacing: 0px;
	color: #3B3BCC;
	opacity: 1;
	cursor: pointer;
}

.slide-active {
    animation-duration: 0.5s;
    animation-name: slideInTransparencia;
}

.slide-out-transparencia {
    animation-duration: 0.5s;
    animation-name: slideOutTransparencia;
}

.arrow{
    position: absolute;
    right: -25%;
}

.arrow-mobile{
    display: none;
    position: absolute;
    bottom: -20%;
    right: 0;
}

@media(max-width: 600px){
    .arrow{
        display: none;
    }

    .arrow-mobile{
        display: block;
    }
}

@keyframes slideInTransparencia {
    from {
      margin-left: 100%;
    }
  
    to {
      margin-left: 2rem;
    }
}

@keyframes slideOutTransparencia {
    from {
      margin-top: -100%;
    }
  
    to {
      margin-top: 2rem;
    }
}
