#Legislacao {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .section-1 {
    width: 70%;
    display: flex;
    align-items: flex-start;
    @media (max-width: 550px) {
      width: 100%;
      padding-left: 20px;
    }
  }
  .section-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section-3 {
    width: 100%;
    max-width: 1268px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 13px;
    padding-right: 13px;
    margin-bottom: 2rem;
    min-height: 520px;
    z-index: 0;
    @media (max-width: 1300px) {
      max-width: 1000px;
      .container-card {
        width: 230px !important;
        height: 305px !important;
      }
      h2 {
        font-size: 2.7rem !important;
      }
    }

    @media (max-width: 1040px) {
      max-width: 750px;
    }
    @media (max-width: 768px) {
      .container-card {
        width: 188px !important;
        height: 191px !important;
      }
      h2 {
        font-size: 2.2rem !important;
      }
      .left {
        justify-content: center !important;
        padding-right: 0 !important;
      }
    }
    @media (max-width: 550px) {
      flex-direction: column;
      max-width: 100%;
      align-items: center;
      .rigth {
        width: 100% !important;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        h3 {
          width: 100%;
          max-width: 140px;
          font-family: 'Teko';
          font-weight: 400;
          color: #2c627a;
          text-transform: uppercase;
          text-align: center;
          margin: 0;
          font-size: 1.32rem;
        }
      }
    }

    .left {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 50px;
    }
    .container-card {
      width: 330px;
      height: 405px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2c627a;
      box-shadow: 0px 5px 15px #00000040;
      position: relative;
    }
    h2 {
      letter-spacing: 0px;
      color: #ffffff;
      margin: 0;
      font-family: 'teko';
      font-weight: 400;
      font-size: 3.8rem;
      text-align: center;
    }
    .arrow {
      position: absolute;
      right: -3rem;
      width: 30%;
      @media (max-width: 768px) {
        bottom: -1.2rem;
        right: 0.4rem;
        width: 20%;
      }
    }
    .rigth {
      width: 50%;
      height: 100%;
      h3 {
        width: 100%;
        max-width: 140px;
        font-family: 'Teko';
        font-weight: 400;
        color: #2c627a;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        font-size: 1.32rem;
      }
    }
    .container-cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      max-height: 750px;
      overflow-y: scroll;
    }
    .card-container {
      transition: all 0.5s ease;
      width: 200px;
      height: 200px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 2;
    }
    .scale {
      transition: all 0.5s ease;
      transform: scale(1.2);
      background-color: #1189c3;
      z-index: 3;
      position: relative;
      h3 {
        color: #ffffff !important;
        text-shadow: 0px 3px 6px #00000029;
        font-size: 1.36rem !important;
      }
    }
    .carousel {
      width: 80%;
    }
    .carousel .rec-carousel-wrapper {
      flex-direction: row !important;
    }
    .carousel .rec .rec-pagination {
      flex-direction: column;
    }
    .carousel .rec .rec-arrow {
      display: none;
    }
    .carousel .rec .rec-dot_active {
      background-color: #aa4f50 !important;
      border-radius: 0;
      width: 15px;
      height: 15px;
      box-shadow: none !important;
      cursor: pointer;
    }
    .carousel .rec .rec-dot {
      background-color: #ff7778;
      border-radius: 0;
      width: 15px;
      height: 15px;
      box-shadow: none !important;
      cursor: pointer;
    }
    ::-webkit-scrollbar {
      width: 9px;
    }

    ::-webkit-scrollbar-track {
      background: #3e92b7;
      border-radius: 15px;
      opacity: 0.9;
      width: 14px;
    }

    ::-webkit-scrollbar-thumb {
      background: #ffffff;
      border-radius: 5px;
      width: 9px !important;
      height: 79px;
    }
  }
}
