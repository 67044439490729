#Comissao {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .section-1 {
    width: 70%;
    display: flex;
    align-items: flex-start;
    @media (max-width: 550px) {
      width: 100%;
      padding-left: 20px;
    }
  }
  .section-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section-3 {
    width: 100%;
    max-width: 1300px;
    margin-left: 2.3rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: row;
    min-height: 765px;
    @media (max-width: 1300px) {
      max-width: 1000px;
      margin-left: 0;
    }
    @media (max-width: 1040px) {
      max-width: 750px;
    }
    @media (max-width: 550px) {
      margin-left: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .container-card {
        width: 188px !important;
        height: 191px !important;
      }
      h2 {
        font-size: 2.2rem !important;
      }
      .left {
        transition: all 0.2s ease-in;
        width: 100% !important;
        justify-content: center !important;
        padding-right: 0 !important;
      }
      .rigth {
        width: 100% !important;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
      }
    }
    h2 {
      letter-spacing: 0px;
      color: #ffffff;
      margin: 0;
      font-family: 'teko';
      font-weight: 400;
      font-size: 3.8rem;
      text-align: center;
    }
    .left {
      width: 40%;
      display: none;//era flex
      align-items: center;
      justify-content: flex-end;
      padding-right: 50px;
      position: relative;
    }
    .rigth {
      width: 100%;
    }
    .arrow {
      position: absolute;
      right: -3rem;
      width: 30%;
      @media (max-width: 768px) {
        bottom: -1.2rem;
        right: 0.4rem;
        width: 20%;
      }
    }
    .container-card {
      transition: all 0.2s ease-in;
      width: 330px;
      height: 405px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2c627a;
      box-shadow: 0px 5px 15px #00000040;
      position: relative;
    }
    .container-card-hide {
      transition: all 0.2s ease-in;
      width: 0;
      opacity: 0;
      position: absolute;
    }
    .container-cards {
      display: flex;
      justify-content: flex-start;
      height: 100%;
      max-height: 750px;
      grid-gap: 5px;
      overflow-y: scroll;
      flex-wrap: wrap;
      margin-top: 15px;
      @media (max-width: 768px) {
        width: 86vw;
        padding: 0 7vw;
      }
    }
    .card-container {
      transition: all 0.5s ease;
      width: 200px;
      height: 200px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 2;
    }
    .scale {
      transition: all 0.5s ease;
      transform: scale(1.2);
      background-color: #1189c3;
      z-index: 3;
      position: relative;
      h3 {
        color: #ffffff !important;
        text-shadow: 0px 3px 6px #00000029;
        font-size: 1.36rem !important;
      }
    }
    h3 {
      width: 100%;
      max-width: 140px;
      font-family: 'Teko';
      font-weight: 400;
      color: #2c627a;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      font-size: 1.32rem;
    }
    ::-webkit-scrollbar {
      width: 9px;
    }

    ::-webkit-scrollbar-track {
      background: #3e92b7;
      border-radius: 15px;
      opacity: 0.9;
      width: 14px;
    }

    ::-webkit-scrollbar-thumb {
      background: #ffffff;
      border-radius: 5px;
      width: 9px !important;
      height: 79px;
    }
  }
  .carousel {
    width: 80%;
  }
  .carousel .rec-carousel-wrapper {
    flex-direction: row !important;
  }
  .carousel .rec .rec-pagination {
    flex-direction: column;
  }
  .carousel .rec .rec-arrow {
    display: none;
  }
  .carousel .rec .rec-dot_active {
    background-color: #aa4f50 !important;
    border-radius: 0;
    width: 15px;
    height: 15px;
    box-shadow: none !important;
    cursor: pointer;
  }
  .carousel .rec .rec-dot {
    background-color: #ff7778;
    border-radius: 0;
    width: 15px;
    height: 15px;
    box-shadow: none !important;
    cursor: pointer;
  }
}
